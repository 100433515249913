import React, {useEffect, useState} from "react";

import {getRoles} from "../services/Roles/Roles.js";

// BUTTONS
import ButtonAdd from "../components/Buttons/ButtonAdd";

import SmallCard from "../components/SmallCard/SmallCard.jsx";
import MediumCard from "../components/MediumCard/MediumCard.jsx";

import {faUserGroup} from "@fortawesome/free-solid-svg-icons";
import {useSelector} from "react-redux";
import {handelDelete} from "../hooks/handleDelete.js";
import openForm from "../components/Formulaire/openForm.js";
import TopGridDisplay from "../components/TopGridDisplay/TopGridDisplay";
import useUsers from "../hooks/useUsers";

const parameterForm = {
    name: {
        label: "Nom",
        order: 0,
    },
    users: {display: false},
    access: {
        app: {
            label: "Application mobile",
            type: "select-one",
            placeholder: "Choisissez",
            options: [
                {label: "Oui", value: true},
                {label: "Non", value: false},
            ],
        },
        admin_ambassador: {
            label: "Administration des ambassadors",
            type: "select-one",
            placeholder: "Choisissez",
            options: [
                {label: "Oui", value: true},
                {label: "Non", value: false},
            ],
        },
        admin_users: {
            label: "Administration des utilisateurs",
            type: "select-one",
            placeholder: "Choisissez",
            options: [
                {label: "Oui", value: true},
                {label: "Non", value: false},
            ],
        },
        admin_full: {
            label: "Administration complète",
            type: "select-one",
            placeholder: "Choisissez",
            options: [
                {label: "Oui", value: true},
                {label: "Non", value: false},
            ],
        },
    },
    createdAt: {
        display: false,
    },
    updatedAt: {
        display: false,
    },
};

function Roles() {
    const isModalOpen = useSelector((state) => state.displayModal.isDisplayed);

    const [listRoles, setListRoles] = useState([]);
    const {users} = useUsers();


    useEffect(() => {
        getRoles().then((roles) => {
            setListRoles(roles);
        });

    }, [isModalOpen]);

    const paramsOpenModal = {
        parameters: parameterForm,
        objectType: "roles",
    };

    const localHandelDelete = (roleToDelete) => {
        handelDelete(
            "roles",
            `${roleToDelete.name}`,
            "Êtes-vous sûr de vouloir supprimer ce rôle ?",
            roleToDelete,
            listRoles,
            setListRoles
        );
    };

    const newRole = {
        name: "",
        access: {
            app: false,
            admin_ambassador: false,
            admin_users: false,
            admin_full: false,
        },
    };

    const generateRolesCards = (roles) => {
        return roles.map((role) => {
            const listUserInRole = users.filter(user => user.roles.includes(role._id)).map(user => ({
                name: user.fullName,
                onClick: () => {
                }
            }));

            return (
                <MediumCard
                    icon={faUserGroup}
                    key={role._id}
                    titre={role.name}
                    textes={[role.users + " utilisateurs"]}
                    menu={[
                        {
                            name: "Supprimer",
                            link: "#",
                            type: "delete",
                            onClick: () => localHandelDelete(role),
                        },
                        {
                            name: "Modifier",
                            link: "#",
                            type: "normal",
                            onClick: () =>
                                openForm({
                                    ...paramsOpenModal,
                                    objectToModify: role,
                                    title: role.name,
                                }),
                        },
                    ]}
                    dropdownItems={listUserInRole
                        // [
                        //     {
                        //         name: "Option 1",
                        //         onClick: () => {
                        //             // Action à effectuer pour l'option 1
                        //         },
                        //     },
                        //     {
                        //         name: "Option 2",
                        //         onClick: () => {
                        //             // Action à effectuer pour l'option 2
                        //         },
                        //     },
                        //     {
                        //         name: "Option 3",
                        //         onClick: () => {
                        //             // Action à effectuer pour l'option 2
                        //         },
                        //     },
                        //     {
                        //         name: "Option 4",
                        //         onClick: () => {
                        //             // Action à effectuer pour l'option 2
                        //         },
                        //     },
                        //     // ... Ajoutez autant d'options que nécessaire
                        // ]
                    }
                />
            );
        });
    };

    return (
        <>
            <TopGridDisplay>
                <SmallCard
                    texte="Roles"
                    keyFigure={listRoles.length}
                    icon={faUserGroup}
                />
            </TopGridDisplay>
            <div className="flex flex-row justify-end -mb-12 -translate-y-12">
                <ButtonAdd
                    onclick={() => {
                        openForm({
                            ...paramsOpenModal,
                            objectToModify: newRole,
                            title: "Nouveau rôle",
                            isNew: true,
                        });
                    }}
                />
            </div>
            <TopGridDisplay>{generateRolesCards(listRoles)}</TopGridDisplay>
        </>
    );
}

export default Roles;
