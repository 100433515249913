"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.AskForHelp = void 0;
var User_1 = require("./User");
var Base_1 = require("./Base");
var AskForHelp = /** @class */ (function (_super) {
    __extends(AskForHelp, _super);
    function AskForHelp(params) {
        var _this = _super.call(this, params) || this;
        var _a = params !== null && params !== void 0 ? params : {}, user = _a.user, message = _a.message, messageType = _a.messageType, subject = _a.subject;
        _this.user = typeof user === "string" ? user : new User_1.User(user);
        _this.message = message;
        _this.messageType = messageType;
        _this.subject = subject;
        return _this;
    }
    Object.defineProperty(AskForHelp.prototype, "messageTypeString", {
        get: function () {
            var _a;
            var defaultMessage = "Demande indéterminée";
            var askHelpType = ["Bug page Home", "Bug page formulaires", "Bug page calendrier", "Bug page profile", "Demande d'aide", "Demande d'informations", "Demande de modifications", "Demande d'ajout", "Autre demande"];
            return this.messageType ? (_a = askHelpType[this.messageType]) !== null && _a !== void 0 ? _a : defaultMessage : defaultMessage;
        },
        enumerable: false,
        configurable: true
    });
    return AskForHelp;
}(Base_1.Base));
exports.AskForHelp = AskForHelp;
