import {useEffect, useState} from 'react';
import {postAPI} from 'src/services/API/requestsManager';
import {Colors} from "../services/API/ObjectTypes/Colors";


function useColors() {
    const [colors, setColors] = useState<any[]>([]);
    const [isLoading, setIsLoading] = useState(true);


    useEffect(() => {
        // @ts-ignore
        postAPI({objectType: "colors", options: "/schools"})
            .then(allColors => {
                setColors(allColors.map((colors: any) => new Colors(colors)))
                setIsLoading(false);
            })
            .catch(error => console.error(error));
    }, []);

    return {colors, isLoading}
}

export default useColors;
