import React from 'react';
import {Link, useParams} from 'react-router-dom';
import SingleAskHelp from "./SingleAskHelp";
import ContainerAskForHelp from "../../components/AskForHelp/ContainerAskForHelp";

function AskHelps() {
    let {idAsk}: { idAsk?: string } = useParams();

    function getMessageTypeText(messageType: number): string {
        const askHelpType = ["Bug page Home", "Bug page formulaires", "Bug page calendrier", "Bug page profile", "Demande d'aide", "Demande d'informations", "Demande de modifications", "Demande d'ajout", "Autre demande"];
        return askHelpType[messageType] ?? "Demande indéterminée";
    }

    function renderRow(user: string, messageType: number, device: string, sendDate: Date, url: string) {
        return (
            <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                <th scope="row"
                    className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                    {user}
                </th>
                <td className="px-6 py-4">
                    {getMessageTypeText(messageType)}
                </td>
                <td className="px-6 py-4">
                    {device}
                </td>
                <td className="px-6 py-4">
                    {sendDate.toLocaleDateString()}
                </td>
                <td className="px-6 py-4 text-right">
                    <Link to={url}
                          className="font-medium text-blue-600 dark:text-blue-500 hover:underline">Edit</Link>
                </td>
            </tr>
        )
    }

    if (idAsk)
        return (<SingleAskHelp idAsk={idAsk}/>)
    else
        return (
            <ContainerAskForHelp>
                <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                    <caption
                        className="p-5 text-lg font-semibold text-left text-gray-900 bg-white dark:text-white dark:bg-gray-800">
                        Demandes d'aide
                    </caption>
                    <thead
                        className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                    <tr>
                        <th scope="col" className="px-6 py-3">
                            Utilisateur
                        </th>
                        <th scope="col" className="px-6 py-3">
                            Type de demande
                        </th>
                        <th scope="col" className="px-6 py-3">
                            Périphérique
                        </th>
                        <th scope="col" className="px-6 py-3">
                            Date
                        </th>
                        <th scope="col" className="px-6 py-3">
                            <span className="sr-only">Edit</span>
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    {renderRow("REY Florian", 1, "iPhone", new Date(), "#")}
                    <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                        <th scope="row"
                            className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                            Microsoft Surface Pro
                        </th>
                        <td className="px-6 py-4">
                            White
                        </td>
                        <td className="px-6 py-4">
                            Laptop PC
                        </td>
                        <td className="px-6 py-4">
                            $1999
                        </td>
                        <td className="px-6 py-4 text-right">
                            <a href="#"
                               className="font-medium text-blue-600 dark:text-blue-500 hover:underline">Edit</a>
                        </td>
                    </tr>
                    <tr className="bg-white dark:bg-gray-800">
                        <th scope="row"
                            className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                            Magic Mouse 2
                        </th>
                        <td className="px-6 py-4">
                            Black
                        </td>
                        <td className="px-6 py-4">
                            Accessories
                        </td>
                        <td className="px-6 py-4">
                            $99
                        </td>
                        <td className="px-6 py-4 text-right">
                            <a href="#"
                               className="font-medium text-blue-600 dark:text-blue-500 hover:underline">Edit</a>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </ContainerAskForHelp>
        );
}

export default AskHelps;
