import clsx from "clsx";
import React, { useState } from "react";
import { Link } from "react-router-dom";

function ButtonMenu({
  rotateIcon = false,
  menuPosition = "left",
  menuItems = [
    {
      name: "Supprimer",
      link: "#",
      type: "delete",
      onClick: () => {},
    },
    {
      name: "Modifier",
      link: "#",
      type: "normal",
      onClick: () => {},
    },
  ],
  ...args
}) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    isMenuOpen ? closeMenu() : openMenu();
  };

  const openMenu = () => {
    setIsMenuOpen(true);
    window.addEventListener("click", closeMenu, true);
  };

  function closeMenu() {
    setIsMenuOpen(false);
    window.removeEventListener("click", closeMenu, true);
  }

  return (
    <div className="relative">
      <button
        type="button"
        className={clsx(
          "align-middle text-sm font-semibold flex justify-end hover:bg-gray-300 rounded-lg",
          isMenuOpen ? "hidden" : "",
          rotateIcon ? "rotate-90" : ""
        )}
        id="menu-button"
        aria-expanded="true"
        aria-haspopup="true"
        onClick={toggleMenu}
      >
        <img src={process.env.PUBLIC_URL + "/assets/images/dots.svg"} alt="" />
      </button>
      <button
        type="button"
        className={clsx(
          "align-middle text-sm font-semibold flex justify-end",
          isMenuOpen ? "" : "hidden",
          rotateIcon ? "rotate-90" : ""
        )}
        id="menu-button"
        aria-expanded="true"
        aria-haspopup="true"
      >
        <img src={process.env.PUBLIC_URL + "/assets/images/dots.svg"} alt="" />
      </button>
      <div
        className={clsx(
          isMenuOpen ? "" : "hidden",
          menuPosition == "left"
            ? "right-[30px] top-[50%] -translate-y-1/2"
            : "right-0 top-[100%] translate-y-1",
          "absolute z-10 w-56 origin-top-right rounded-md overflow-hidden bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none font-normal"
        )}
        role="menu"
        aria-orientation="vertical"
        aria-labelledby="menu-button"
        tabIndex="-1"
      >
        <div role="none">
          {menuItems.map((item, index) => {
            if (item.link)
              return (
                <Link
                  key={item + index}
                  to={item.link}
                  className={clsx(
                    "text-gray-700 block px-4 py-2 text-sm",
                    item.type === "delete"
                      ? "bg-red-200 hover:bg-red-300"
                      : "hover:bg-gray-50"
                  )}
                  onClick={item.onClick}
                >
                  {item.name}
                </Link>
              );
            return (
              <div
                key={item + index}
                className={clsx(
                  "cursor-pointer text-gray-700 block px-4 py-2 text-sm",
                  item.type === "delete"
                    ? "bg-red-200 hover:bg-red-300"
                    : "hover:bg-gray-50"
                )}
                onClick={item.onClick}
              >
                {item.name}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default ButtonMenu;
