import React, {useRef} from 'react';
import {SubmitHandler, useForm} from "react-hook-form"
import clsx from "clsx";

import "../../assets/style/security.css";
import {useAppSelector} from "../../store/hooks";
import {putAPI} from "../../services/API/requestsManager";

interface IFormInput {
    oldPassword: string
    newPassword: string
    confirmPassword: string
}

function Security() {
    const userInfos = useAppSelector(state => state.userInfos);
    const {register, formState: {errors}, handleSubmit, watch} = useForm<IFormInput>()
    const onSubmit: SubmitHandler<IFormInput> = (data) => {
        // Submit new password
        return putAPI({
            objectType: "users",
            idToModify: userInfos.id + "/password",
            dataToTransfert: {
                password: data.oldPassword,
                newPassword: data.newPassword,
            },
        }).then((resp) => {
            if (resp) {
                return "Mot de passe enregistré"
            } else {
                console.error(resp);
                throw Error("Enregistrement impossible du nouveau mot de passe");
            }
        });
    };

    const password = useRef({});
    password.current = watch("newPassword", "");

    return (
        <div className={"w-full flex justify-center"}>
            <div className="max-w-[600px] p-12 rounded-[40px] flex justify-center flex-col w-full">
                <h2 className="text-2xl font-bold text-center mb-10">Modifier mon mot de passe</h2>
                <form onSubmit={handleSubmit(onSubmit)} className={"flex flex-col gap-5"}>
                    <div className={clsx("mb-2")}>
                        <label
                            htmlFor={"oldPassword"}
                            className="block mb-1 text-sm font-medium text-gray-900"
                        >
                            Ancien mot de passe <span className='text-red-500'>{"*"}</span>
                        </label>
                        <input
                            type={"password"}
                            placeholder={"Entrer l'ancien mot de passe"}
                            {...register("oldPassword", {required: "L'ancien mot de passe est nécessaire pour le modifier."})}
                            className={clsx("bg-gray-50 border text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5", !errors.oldPassword ? "border-gray-300" : "border-red-300")}
                        />
                        {errors.oldPassword && <span className={"error"}>{errors.oldPassword?.message}</span>}
                    </div>
                    <div className={clsx("mb-2")}>
                        <label
                            htmlFor={"newPassword"}
                            className="block mb-1 text-sm font-medium text-gray-900"
                        >
                            Nouveau mot de passe <span className='text-red-500'>{"*"}</span>
                        </label>
                        <input
                            type="password"
                            placeholder={"Entrer le nouveau mot de passe"}
                            {...register("newPassword", {
                                required: true,
                                pattern: {
                                    value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$@!%&*?])[A-Za-z\d#$@!%&*?]{8,30}$/,
                                    message: "Le mot de passe ne respecte pas le niveau de sécurité minimum."
                                }
                            })}
                            className={clsx("bg-gray-50 border text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5", !errors.newPassword ? "border-gray-300" : "border-red-300")}
                        />
                        {errors.newPassword && <span className={"error"}>{errors.newPassword?.message}</span>}
                    </div>
                    <div className={clsx("mb-2")}>
                        <label
                            htmlFor={"confirmPassword"}
                            className="block mb-1 text-sm font-medium text-gray-900"
                        >
                            Confirmer le nouveau mot de passe <span className='text-red-500'>{"*"}</span>
                        </label>
                        <input
                            type={"password"}
                            placeholder={"Répéter le nouveau mot de passe"}
                            {...register("confirmPassword", {
                                required: true,
                                pattern: {
                                    value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$@!%&*?])[A-Za-z\d#$@!%&*?]{8,30}$/,
                                    message: "Le mot de passe ne respecte pas le niveau de sécurité minimum."
                                },
                                validate: value =>
                                    value === password.current || "Le mot de passe ne correspond pas."
                            })}
                            className={clsx("bg-gray-50 border text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5", !errors.confirmPassword ? "border-gray-300" : "border-red-300")}
                        />
                        {errors.confirmPassword && <span className={"error"}>{errors.confirmPassword?.message}</span>}
                    </div>

                    <span><span className='text-red-500'>{"*"}</span> champs obligatoires</span>

                    <input type="submit" className={"mt-3 themed-btn rounded py-2 px-8 w-fit m-auto"}
                           value={"Changer mon mot de passe"}/>
                </form>
            </div>
        </div>
    );
}

export default Security;
