import React, {useState} from "react";
import clsx from "clsx";

// BUTTONS
import ButtonMenu from "../Buttons/ButtonMenu.jsx";

// ICONS
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faInbox} from "@fortawesome/free-solid-svg-icons";

function MediumCard({
                        titre = null,
                        textes = null,
                        icon = faInbox,
                        menu = [],
                        dropdownItems = [],
                    }) {
    const [isDropdownOpen, setIsDropdownOpen] = useState(false); // State pour gérer l'affichage de la liste déroulante

    return (
        <>
            <div className="flex flex-col">
                <div
                    className={clsx(
                        "w-full h-fit-content flex flex-row rounded-3xl px-6 py-4 bg-gray-50 cursor-pointer transition duration-300 hover:bg-gray-200 z-10"
                    )}
                    onClick={() => dropdownItems.length > 0 ? setIsDropdownOpen(!isDropdownOpen) : null}
                >
                    <div className="flex justify-center items-center">
                        <FontAwesomeIcon icon={icon} size="3x"/>
                    </div>
                    <div className="ml-7 flex flex-col" style={{flex: 1}}>
                        <h3 className="text mb-1">{titre}</h3>
                        <p className="text-sm">{textes[0]}</p>
                        <p className="text-sm">{textes[1]}</p>
                        <p className="text-sm">{textes[2]}</p>
                    </div>

                    <div className="flex items-center justify-center">
                        <ButtonMenu rotateIcon={true} menuItems={menu}/>
                    </div>
                </div>
                {/* List dropdown */}
                <div
                    className={clsx(
                        "transition-max-height overflow-hidden transition-all",
                        {"h-auto": isDropdownOpen}, isDropdownOpen ? "overflow-scroll max-h-80" : "overflow-hidden max-h-0",
                    )}
                >
                    <div
                        className="origin-top-right -top-1 z-0 relative w-fit-content rounded-b-lg shadow-lg bg-gray-50 ring-1 ring-black ring-opacity-5 focus:outline-none ml-5 mr-5"
                        role="menu"
                        aria-orientation="vertical"
                        aria-labelledby="options-menu"
                    >
                        <div
                            className="py-1"
                            role="menu"
                            aria-orientation="vertical"
                            aria-labelledby="options-menu"
                        >
                            {dropdownItems.map((item, index) => (
                                <button
                                    key={index}
                                    className="block px-4 py-2 text-sm text-gray-700 w-full text-left"
                                    role="menuitem"
                                    onClick={item.onClick}
                                >
                                    {item.name}
                                </button>
                            ))}
                        </div>
                    </div>
                </div>
                {/* End of list dropdown */}
            </div>
        </>
    );
}

export default MediumCard;
