import React from 'react';

import '../assets/style/privacy-policy.css';

function PrivacyPolicy() {
    const options: Intl.DateTimeFormatOptions = {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'};
    const updateDate = new Date("13 Sep 2023");

    
    return (
        <main id={"privacy-policy"}>
            <header>
                <h1>Politique de Confidentialité de l'Association Ambassador</h1>
                <p>Dernière mise à jour : {updateDate.toLocaleDateString(undefined, options)}</p>
            </header>

            <section>
                <h2>Introduction</h2>
                <p>L'Association Ambassador (ci-après dénommée "nous", "notre" ou "l'Association") s'engage à
                    protéger
                    la confidentialité de vos informations personnelles. Cette politique de confidentialité explique
                    comment nous collectons, utilisons, protégeons et gérons vos données personnelles lorsque vous
                    utilisez notre application mobile Ambassador.</p>
                <p>Veuillez prendre le temps de lire attentivement cette politique pour comprendre comment nous
                    traitons
                    vos données personnelles.</p>
            </section>

            <section>
                <h2>Données Collectées</h2>
                <p>L'Association Ambassador collecte les informations personnelles suivantes, qui sont obligatoires
                    pour
                    le bon fonctionnement de l'application mobile Ambassador :</p>
                <ul>
                    <li>Nom et prénom</li>
                    <li>Adresse e-mail</li>
                    <li>Date de naissance</li>
                    <li>Taille de t-shirt</li>
                    <li>École au sein du groupe NEXT-U</li>
                </ul>
                <p>Ces données sont nécessaires pour vous fournir une expérience complète et personnalisée dans
                    notre
                    application.</p>
            </section>

            <section>
                <h2>Utilisation des Données</h2>
                <p>Les données collectées sont utilisées exclusivement pour le bon fonctionnement de l'application
                    mobile Ambassador. Nous les utilisons pour les finalités suivantes :</p>
                <ul>
                    <li>Gestion des comptes utilisateur.</li>
                    <li>Personnalisation de l'expérience utilisateur.</li>
                    <li>Communication d'informations spécifiques liées à l'Association Ambassador et à ses
                        activités.
                    </li>
                    <li>Amélioration continue de l'application et de ses fonctionnalités.</li>
                </ul>
            </section>

            <section>
                <h2>Partage des Données</h2>
                <p>L'Association Ambassador s'engage à ne pas transmettre vos données personnelles à d'autres
                    entreprises ou organisations. Vos informations personnelles resteront strictement
                    confidentielles et
                    ne seront utilisées que dans le cadre du bon fonctionnement de l'application Ambassador.</p>
            </section>

            <section>
                <h2>Hébergement des Données</h2>
                <p>Les données collectées sont hébergées sur les serveurs d'IONOS, situés en Allemagne. Nous avons
                    choisi cette option pour garantir la sécurité et la protection de vos données, conformément aux
                    lois
                    de l'Union européenne.</p>
            </section>

            <section>
                <h2>Contact</h2>
                <p>Vous pouvez nous contacter à l'adresse électronique suivante pour toute question ou préoccupation
                    concernant votre vie privée ou cette politique de confidentialité :</p>
                <p>Adresse e-mail de contact : <a href="mailto:ambassadors@next-u.fr">ambassadors@next-u.fr</a></p>
            </section>

            <section>
                <h2>Adresse Physique</h2>
                <p>Nos locaux sont situés à l'adresse suivante :</p>
                <p>6 Place Camille Georges<br/>69002 Lyon<br/>France</p>
            </section>

            <section>
                <h2>Modifications de cette Politique</h2>
                <p>L'Association Ambassador se réserve le droit de mettre à jour cette politique de confidentialité
                    de
                    temps à autre pour refléter les changements dans nos pratiques de collecte et de gestion des
                    données. La version la plus récente de cette politique sera toujours disponible sur notre site
                    Web,
                    avec la date de la dernière mise à jour.</p>
            </section>
        </main>
    );
}

export default PrivacyPolicy;
