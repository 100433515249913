import {createSlice} from '@reduxjs/toolkit'

export interface tokensType {
    accessToken: string,
    refreshToken: string,
    permanentToken: string,
}

const initialTokensState = {
    accessToken: null,
    refreshToken: null,
    permanentToken: null
}

export const tokensSlice = createSlice({
    name: 'tokens',
    initialState: initialTokensState,
    reducers: {
        setAccessToken: (state, action) => {
            state.accessToken = action.payload;
        },
        setRefreshToken: (state, action) => {
            state.refreshToken = action.payload;
        },
        setBothToken: (state, action) => {
            state.refreshToken = action.payload.refreshToken;
            state.accessToken = action.payload.accessToken;
            if (action.payload.permanentToken) {
                state.permanentToken = action.payload.permanentToken;
            }
        },
        removeAccess: (state) => {
            state.refreshToken = null;
            state.accessToken = null;
            state.permanentToken = null;
        }
    }
})

// Action creators are generated for each case reducer function
export const {setAccessToken, setRefreshToken, setBothToken, removeAccess} = tokensSlice.actions

export default tokensSlice.reducer
