import React, {useState} from "react";
import {Importer, ImporterField} from "react-csv-importer";
import {frFR} from "./locale_frFR";
import "react-csv-importer/dist/index.css";
import {postAPI} from "../../services/API/requestsManager";
import clsx from "clsx";

const tabAssoc = {
    "Nom": "lastname",
    "Prénom": "firstname",
    "Email": "email",
    "École": "school"
}

function CSVImporterComponent() {
    const dataRows = [];
    const [status, setStatus] = useState(0);
    const [creation, setCreation] = useState({
        inserted: [],
        error: []
    })

    const translateData = (dataToTranslate) => {
        for (const [key, value] of Object.entries(tabAssoc)) {
            dataToTranslate.map(row => delete Object.assign(row, {[value]: row[key]})[key])
        }
        return dataToTranslate
    }

    const saveToApi = () => {
        const translatedData = translateData(dataRows)
        postAPI({
            objectType: "users",
            options: "/import-csv-ambassadors",
            dataToTransfert: {ambassadors: dataRows}
        }).then(resp => {
            setStatus(1);
            setCreation(resp)
        }).catch(err => {
            setStatus(2);
        })
    }


    return (
        <div className={"p-3"}>
            {status > 0 &&
                <div className={clsx("p2",)}>
                    {status === 1 && `${creation.inserted.length} utilisateur(s) créé(s), ${creation.error.length} utilisateur(s) non créé(s) pour cause d'erreur`}
                    {status === 2 && "Une erreur est survenue lors de l'enregistrement des données."}
                </div>}
            <Importer
                locale={frFR}
                dataHandler={async (rows) => {
                    console.log("received batch of rows", rows);
                    dataRows.push(...rows);
                    await new Promise((resolve) => setTimeout(resolve, 500));
                }}
                chunkSize={10000}
                defaultNoHeader={false}
                restartable={false}
                onStart={({file, fields}) => {
                    console.log("starting import of file", file, "with fields", fields);
                }}
                onComplete={({file, fields}) => {
                    console.log("finished import of file", file, "with fields", fields);
                    saveToApi();
                }}
                onClose={() => {
                    console.log("importer dismissed");
                }}>
                <ImporterField name="Nom" label="Nom"/>
                <ImporterField name="Prénom" label="Prénom"/>
                <ImporterField name="Email" label="Email"/>
                <ImporterField name="École" label="École"/>
            </Importer>
        </div>
    );
}

export default CSVImporterComponent;
