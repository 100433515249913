"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.File = void 0;
var Base_1 = require("./Base");
var School_1 = require("./School");
var File = /** @class */ (function (_super) {
    __extends(File, _super);
    function File(params) {
        var _this = _super.call(this, params) || this;
        var _a = params !== null && params !== void 0 ? params : {}, name = _a.name, file = _a.file, size = _a.size, type = _a.type, original_file_name = _a.original_file_name, school = _a.school;
        _this.name = name !== null && name !== void 0 ? name : "";
        _this.size = size !== null && size !== void 0 ? size : 0;
        _this.type = type !== null && type !== void 0 ? type : "";
        if (typeof school === "string")
            if (school.match(/^[0-9a-fA-F]{24}$/))
                _this.school = school ? new School_1.School({ _id: school }) : null;
            else
                _this.school = null;
        else
            _this.school = school ? new School_1.School(school) : null;
        _this.file = file;
        _this.original_file_name = original_file_name !== null && original_file_name !== void 0 ? original_file_name : "";
        _this.table = "files";
        _this.addAssocLabel("name", "Nom");
        _this.addAssocLabel("size", "Taille");
        _this.addAssocLabel("type", "Type");
        _this.addAssocLabel("school", "Ecole");
        _this.addAssocLabel("file", "Fichier");
        return _this;
    }
    Object.defineProperty(File.prototype, "sizeText", {
        get: function () {
            var _a;
            return byteConverter((_a = this.size) !== null && _a !== void 0 ? _a : 0, 2);
        },
        enumerable: false,
        configurable: true
    });
    File.prototype.addFormFields = function (_a) {
        var objectJson = _a.objectJson, objectProps = _a.objectProps;
        objectProps.size.display = false;
        objectProps.original_file_name.display = false;
        objectProps.file.type = "file";
        objectProps.type.display = false;
        objectProps.school.type = "select-one";
        objectProps.school.relatedRepo = "schools";
        return { objectJson: objectJson, objectProps: objectProps };
    };
    ;
    return File;
}(Base_1.Base));
exports.File = File;
function byteConverter(bytes, decimals, only) {
    var K_UNIT = 1024;
    var SIZES = ["Octets", "Ko", "Mo", "Go", "To", "Po"];
    if (bytes == 0)
        return "0 Octet";
    if (only === "Mo")
        return (bytes / (K_UNIT * K_UNIT)).toFixed(decimals) + " Mo";
    var i = Math.floor(Math.log(bytes) / Math.log(K_UNIT));
    return parseFloat((bytes / Math.pow(K_UNIT, i)).toFixed(decimals)) + " " + SIZES[i];
}
