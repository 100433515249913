import React from 'react';

import '../assets/style/privacy-policy.css';

function AskHelp() {
    const options: Intl.DateTimeFormatOptions = {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'};
    const updateDate = new Date("13 Sep 2023");


    return (
        <main id={"privacy-policy"}>
            <header>
                <h1>Demande d'aide</h1>
                <p>Dernière mise à jour : {updateDate.toLocaleDateString(undefined, options)}</p>
            </header>


            <section>
                <h2>Demande par l'application</h2>
                <p>L'application Ambassador, en étant connecté, permet de demander de l'aide par la page "Aide"
                    dans les paramètres du compte utilisateur</p>
            </section>

            <section>
                <h2>Demande par E-Mail</h2>
                <p>Si vous préférez envoyer une demande d'aide par e-mail, vous pouvez le faire en utilisant l'adresse
                    suivante : <a
                        href="mailto:ambassadors@next-u.fr?subject=Demande d'aide Ambassador&body=Bonjour%0AJe%20m'appelle%20%5BVotre%20Nom%5D%20et%20j'aurais%20besoin%20d'aide%20dans%20votre%20application%20Ambassador.%0AMon%20probl%C3%A8me%20%3A%0A%5BJe%20d%C3%A9cris%20ici%20mon%20probl%C3%A8me%5D">ambassadors@next-u.fr</a>.
                </p>

                <p>Veuillez inclure les informations suivantes dans votre e-mail :</p>

                <ul>
                    <li>Votre nom complet</li>
                    <li>Votre adresse e-mail</li>
                    <li>Une description détaillée de votre demande ou question</li>
                </ul>

                <p>Nous traiterons votre demande dès que nous la recevrons et vous répondrons dans les plus brefs
                    délais.</p>
            </section>
        </main>
    );
}

export default AskHelp;
