import React, {useEffect, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faX} from "@fortawesome/free-solid-svg-icons";
import clsx from "clsx";
import {useTheme} from "@mui/material";
import chroma from "chroma-js";
import House from "../Icons/House";
import User from "../Icons/User";
import Calendar from "../Icons/Calendar";
import Form from "../Icons/Form";

export default function ColorComponentChanger(props) {
    const {primaryColor, secondaryColor} = props;
    /* Static Data */
    const labels = {
        inputEmail: "Email",
        infoEmail: "lola.louvounier@next-u.fr",
        inputPhone: "Téléphone portable",
        infoPhone: "06439876",
        nextEvent: "5",
        eventDone: "8",
        infoNextEvent: "Evènements à venir",
        infoEventDone: "Evènements fait cette année",
        openButton: "Ouvrir",
        closeButton: "Fermer",
        progressRank: "Rang",
        progressLevel: "Niveau",
        progressPoint: "Point",
    };
    const theme = useTheme();
    const [isBlinking, setIsBlinking] = useState(true);
    const [elementHover, setElementHover] = useState("");

    useEffect(() => {
        setTimeout(
            () => {
                setIsBlinking(!isBlinking);
            },
            isBlinking ? 1500 : 500
        );
    }, [isBlinking]);

    // -- Color function
    const primaryColorDark = chroma(primaryColor).darken().hex();
    const primaryColorExtradark = chroma(primaryColor).darken(2).hex();
    const primaryColorLight = chroma(primaryColor).alpha(0.3).hex();
    const primaryColorExtraLight = chroma(primaryColor).alpha(0.1).hex();
    // -----------------------------------------------------------------
    const secondaryColorDark = chroma(secondaryColor).darken().hex();
    const secondaryColorExtradark = chroma(secondaryColor).darken(2).hex();
    const secondaryColorLight = chroma(secondaryColor).alpha(0.3).hex();
    const secondaryColorExtraLight = chroma(secondaryColor).alpha(0.1).hex();

    return (
        <div className="flex flex-row ">
            <div className="flex flex-col w-2/3">
                <div className="flex flex-row items-center gap-40 w-full">
                    <div className="flex flex-col gap-1">
                        <h4 className="text-1xl underline mb-3">Palette générées :</h4>
                        <div
                            className={"w-80 h-12"}
                            style={{backgroundColor: primaryColorExtradark}}
                        ></div>
                        <div
                            className={"w-80 h-12"}
                            style={{backgroundColor: primaryColorDark}}
                        ></div>
                        <div
                            className={"w-80 h-12"}
                            style={{backgroundColor: primaryColor}}
                        ></div>
                        <div
                            className={"w-80 h-12"}
                            style={{backgroundColor: primaryColorLight}}
                        ></div>
                        <div
                            className={"w-80 h-12"}
                            style={{backgroundColor: primaryColorExtraLight}}
                        ></div>
                    </div>
                    <div className="flex flex-row">
                        <div className="flex flex-col 2xl:flex-row gap-10 2xl:gap-24">
                            <div className="">
                                <div
                                    className="rounded-lg w-32 h-32 drop-shadow-xl"
                                    style={{backgroundColor: primaryColor}}
                                >
                                    <h4 className="text-4xl pt-5 text-white font-bold text-center">
                                        {labels.eventDone}
                                    </h4>
                                    <h4 className="text-1xl text-white text-center">
                                        {labels.infoEventDone}
                                    </h4>
                                </div>
                            </div>
                            <div className="flex flex-col justify-between">
                                <div className="drop-shadow-xl">
                                    <button
                                        className="text-white font-bold py-2 px-4 rounded drop-shadow-xl"
                                        onMouseEnter={() => setElementHover("closeButton")}
                                        onMouseLeave={() => setElementHover("")}
                                        style={{
                                            backgroundColor:
                                                elementHover === "closeButton"
                                                    ? primaryColorLight
                                                    : primaryColor,
                                        }}
                                    >
                                        {labels.closeButton}
                                    </button>
                                </div>
                                <div className="drop-shadow-xl">
                                    <button
                                        className="bg-transparent  font-semibold py-2 px-4 border rounded drop-shadow-xl"
                                        onMouseEnter={() => setElementHover("openButton")}
                                        onMouseLeave={() => setElementHover("")}
                                        style={{
                                            backgroundColor:
                                                elementHover === "openButton"
                                                    ? primaryColor
                                                    : "transparent",
                                            borderColor:
                                                elementHover === "openButton"
                                                    ? "transparent"
                                                    : primaryColor,
                                            color:
                                                elementHover === "openButton" ? "white" : primaryColor,
                                        }}
                                    >
                                        {labels.openButton}
                                    </button>
                                </div>
                            </div>
                            <div className="flex flex-col justify-between ">
                                <div
                                    className="h-12 w-20 rounded-3xl flex flex-row items-center justify-center m1"
                                    style={{
                                        background: primaryColorDark,
                                    }}
                                >
                                    <h4
                                        className="text-1xl text-white text-center ml-1"
                                        style={{
                                            color: theme.palette.getContrastText(primaryColorDark),
                                        }}
                                    >
                                        ON&nbsp;
                                    </h4>
                                    <div className="bg-white h-10 w-10 rounded-full"></div>
                                </div>
                                <div
                                    className="h-12 w-20 rounded-3xl flex flex-row-reverse items-center justify-center m1"
                                    style={{
                                        background: primaryColorLight,
                                    }}
                                >
                                    <h4
                                        className="text-1xl text-center ml-1"
                                        style={{
                                            color: "black",
                                        }}
                                    >
                                        OFF
                                    </h4>
                                    <div className="bg-white h-10 w-10 rounded-full"></div>
                                </div>
                            </div>
                            <div className="flex flex-col justify-between ">
                                <div
                                    className="h-10 w-60"
                                    style={{
                                        background: primaryColor,
                                    }}
                                >
                                    <h4
                                        className="text-1xl text-center ml-1"
                                        style={{
                                            color: "black",
                                        }}
                                    >
                                        LOGO
                                    </h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="bg-gray-600 w-40 h-0.5 relative block m-2 rounded-full"></div>
                <div className="flex items-center gap-40 ">
                    <div className="flex flex-col gap-1">
                        <div
                            className={"w-80 h-12"}
                            style={{backgroundColor: secondaryColorExtradark}}
                        ></div>
                        <div
                            className={"w-80 h-12"}
                            style={{backgroundColor: secondaryColorDark}}
                        ></div>
                        <div
                            className={"w-80 h-12"}
                            style={{backgroundColor: secondaryColor}}
                        ></div>
                        <div
                            className={"w-80 h-12"}
                            style={{backgroundColor: secondaryColorLight}}
                        ></div>
                        <div
                            className={"w-80 h-12"}
                            style={{backgroundColor: secondaryColorExtraLight}}
                        ></div>
                    </div>
                    <div className="flex flex-col">
                        <div className="flex items-center justify-evenly flex-col 2xl:flex-row gap-10 2xl:gap-24">
                            <div
                                className="rounded-lg w-32 h-32 drop-shadow-xl"
                                style={{
                                    backgroundColor: secondaryColor,
                                }}
                            >
                                <h4 className="text-4xl pt-5 text-white font-bold text-center">
                                    {labels.nextEvent}
                                </h4>
                                <h4 className="text-1xl text-white text-center">
                                    {labels.infoNextEvent}
                                </h4>
                            </div>
                            <div className="flex flex-col">
                                <div className="">
                                    <h4 className="text-1xl pt-5 font-bold text-start">
                                        {labels.inputEmail}
                                    </h4>
                                    <div
                                        className="w-7 h-0.5 relative block rounded-full mb-2"
                                        style={{
                                            backgroundColor: secondaryColor,
                                        }}
                                    ></div>
                                    <div
                                        className="border-green-500 border p-2 rounded bg-white flex flex-row justify-between items-center w-64"
                                        style={{
                                            boxShadow:
                                                secondaryColorExtraLight +
                                                " 0px 14px 28px, " +
                                                secondaryColorLight +
                                                " 0px 10px 10px",
                                        }}
                                    >
                                        <h4 className="pl-1 pr-1 text-1xl text-start">
                                            {labels.infoEmail}
                                        </h4>
                                        <FontAwesomeIcon
                                            icon={faCheck}
                                            className="h-3 w-3 bg-green-400 rounded-full p-1"
                                        />
                                    </div>
                                </div>
                                <div className="">
                                    <h4 className="text-1xl pt-5 font-bold text-start">
                                        {labels.inputPhone}
                                    </h4>
                                    <div
                                        className="w-7 h-0.5 relative block rounded-full mb-2"
                                        style={{
                                            backgroundColor: secondaryColor,
                                        }}
                                    ></div>
                                    <div
                                        className="border-red-500 border p-2 rounded bg-white flex flex-row justify-between items-center w-64"
                                        style={{
                                            boxShadow:
                                                secondaryColorExtraLight +
                                                " 0px 14px 28px, " +
                                                secondaryColorLight +
                                                " 0px 10px 10px",
                                        }}
                                    >
                                        <h4
                                            className={clsx(
                                                "pl-1 text-1xl text-start pr-1 ",
                                                isBlinking ? "border-r-2 border-gray-400" : ""
                                            )}
                                        >
                                            {labels.infoPhone}
                                        </h4>
                                        <FontAwesomeIcon
                                            icon={faX}
                                            className="h-3 w-3 bg-red-400 rounded-full p-1"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="w-fit">
                                <h4 className="text-1xl text-start text-gray-700">
                                    {labels.progressRank}
                                </h4>
                                <h4 className="text-1xl text-start text-gray-700">
                                    {labels.progressLevel}
                                </h4>
                                <h4 className="text-1xl text-start text-gray-700">
                                    {labels.progressPoint}
                                </h4>
                                <div
                                    className="w-7 h-0.5 relative block rounded-full mb-2"
                                    style={{
                                        backgroundColor: secondaryColor,
                                    }}
                                ></div>
                                <div
                                    className="rounded-full h-fit w-64 border-2 items-center relative"
                                    style={{
                                        boxShadow:
                                            secondaryColorExtraLight +
                                            " 0px 14px 28px, " +
                                            secondaryColorLight +
                                            " 0px 10px 10px",
                                    }}
                                >
                                    <div
                                        className="rounded-full h-6 w-48 items-center"
                                        style={{
                                            background:
                                                "linear-gradient(90deg, " +
                                                secondaryColorDark +
                                                ", " +
                                                secondaryColorLight +
                                                ")",
                                        }}
                                    ></div>
                                    <h4 className="absolute right-2 top-1/2 -translate-y-1/2 font-bold">
                                        80%
                                    </h4>
                                </div>
                            </div>
                            <div className="w-fit">
                                <div
                                    className="h-fit w-64 border-2 items-center relative drop-shadow-lg rounded-md px-3 py-2">
                                    <div
                                        className="flex flex-row items-center justify-between bg-color-white">
                                        <House primary={primaryColorLight} secondary={primaryColor}/>
                                        <User primary={primaryColorLight} secondary={primaryColor}/>
                                        <Calendar primary={primaryColor} secondary={primaryColorLight}/>
                                        <Form primary={primaryColorLight} secondary={primaryColor}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
