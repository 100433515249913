import {Answer} from "./Answer";
import {Field} from "./Field";
import {Form} from "./Form";
import {Role} from "./Role";
import {School} from "./School";
import {TypeField} from "./TypeField";
import {User} from "./User";
import {Ambassador} from "./Ambassador";
import {Evenement} from "./Evenement";
import {File} from "./File";
import {HubspotForm} from "./HubSpotForm";
import {Notification} from "./Notification";
import {AskForHelp} from "./AskForHelp";
import {Mail} from "./Mail";
import {Colors} from "./Colors";

export default {
    ambassador: Ambassador,
    colors: Colors,
    answers: Answer,
    fields: Field,
    files: File,
    forms: Form,
    hubspotForms: HubspotForm,
    askForHelp: AskForHelp,
    mails: Mail,
    notifications: Notification,
    roles: Role,
    schools: School,
    typeFields: TypeField,
    users: User,
    events: Evenement
}
