import { ImporterLocale } from "react-csv-importer";

/* eslint-disable @typescript-eslint/explicit-module-boundary-types -- toutes les exportations sont de type ImporterLocale, qui est déjà entièrement typé */
export const frFR: ImporterLocale = {
    general: {
        goToPreviousStepTooltip: 'Aller à l\'étape précédente'
    },

    fileStep: {
        initialDragDropPrompt:
            'Faites glisser et déposez le fichier CSV ici ou cliquez pour le sélectionner dans le dossier',
        activeDragDropPrompt: 'Déposez le fichier CSV ici...',

        getImportError: (message) => `Erreur d'importation : ${message}`,
        getDataFormatError: (message) => `Veuillez vérifier le format des données : ${message}`,
        goBackButton: 'Revenir en arrière',
        nextButton: 'Choisir les colonnes',

        rawFileContentsHeading: 'Contenu brut du fichier',
        previewImportHeading: 'Aperçu de l\'importation',
        dataHasHeadersCheckbox: 'Les données ont des en-têtes',
        previewLoadingStatus: 'Chargement de l\'aperçu...'
    },

    fieldsStep: {
        stepSubtitle: 'Sélectionner les colonnes',
        requiredFieldsError: 'Veuillez attribuer toutes les champs requis',
        nextButton: 'Importer',

        dragSourceAreaCaption: 'Colonnes à importer',
        getDragSourcePageIndicator: (currentPage: number, pageCount: number) =>
            `Page ${currentPage} sur ${pageCount}`,
        getDragSourceActiveStatus: (columnCode: string) =>
            `Attribution de la colonne ${columnCode}`,
        nextColumnsTooltip: 'Afficher les colonnes suivantes',
        previousColumnsTooltip: 'Afficher les colonnes précédentes',
        clearAssignmentTooltip: 'Effacer l\'attribution de la colonne',
        selectColumnTooltip: 'Sélectionner une colonne pour l\'attribution',
        unselectColumnTooltip: 'Désélectionner une colonne',

        dragTargetAreaCaption: 'Champs cibles',
        getDragTargetOptionalCaption: (field) => `${field} (facultatif)`,
        getDragTargetRequiredCaption: (field) => `${field} (obligatoire)`,
        dragTargetPlaceholder: 'Faites glisser une colonne ici',
        getDragTargetAssignTooltip: (columnCode: string) =>
            `Attribuer la colonne ${columnCode}`,
        dragTargetClearTooltip: 'Effacer l\'attribution de la colonne',

        columnCardDummyHeader: 'Champ non attribué',
        getColumnCardHeader: (code) => `Colonne ${code}`
    },

    progressStep: {
        stepSubtitle: 'Importation',
        uploadMoreButton: 'Importer davantage',
        finishButton: 'Terminer',
        statusError: 'Impossible d\'importer',
        statusComplete: 'Terminé',
        statusPending: 'Importation en cours...',
        processedRowsLabel: 'Lignes traitées :'
    }
};
