import {useEffect, useState} from 'react';
import {Mail} from "../services/API/ObjectTypes/Mail";
import {Mails} from "../services/API/Repositories/Mails";

const MailsRepo = new Mails();

function useMail() {
    const [mails, setMails] = useState<Mail[]>([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        MailsRepo.getAll()
            .then(allMails => {
                setMails(allMails)
                setIsLoading(false);
            })
            .catch(error => console.error(error));
    }, []);

    return {mails, isLoading, repo: MailsRepo}
}

export default useMail;
