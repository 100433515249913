import React, {useEffect, useState} from 'react';
import CheckBox from "../components/CheckBox/CheckBox";
import moment from "moment/moment";
import ButtonMenu from "../components/Buttons/ButtonMenu";
import {Files} from "../services/API/Repositories/Files";
import {display, setArgsForComponent, setComponent, setTitle} from "../store/displayModal/displayModal";
import {useAppDispatch, useAppSelector} from "../store/hooks";
import {handelDelete} from "../hooks/handleDelete";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFile, faFileImage, faFilePdf} from "@fortawesome/free-solid-svg-icons";

const repoFiles = new Files();

function AmbassadorAttachments(props) {
    const dispatch = useAppDispatch();
    const displayModal = useAppSelector(state => state.displayModal);
    const [fileList, setFileList] = useState([]);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isMultipleFileSelected, setIsMultipleFileSelected] = useState(false);
    const [isMenuShown, setIsMenuShown] = useState(false);
    const [forceUpdate, setForceUpdate] = useState(true);

    useEffect(() => {
        repoFiles.getAll().then(files => {
            setFileList(files);
        })
    }, [displayModal.isDisplayed, forceUpdate]);

    const selectFileById = (idFile) => {
        document.getElementById("file-" + idFile).checked =
            !document.getElementById("file-" + idFile).checked;
        changeSelection();
    };

    const getIconForType = (type) => {
        if (type.match(/pdf/i)) {
            return <FontAwesomeIcon icon={faFilePdf}/>
        } else if (type.match(/image/i)) {
            return <FontAwesomeIcon icon={faFileImage}/>
        } else {
            return <FontAwesomeIcon icon={faFile}/>
        }
    }

    function generateListedFiles(files) {
        let nodeList = [];
        files.forEach((file) => {
            nodeList.push(
                <tr
                    key={file._id}
                    className="bg-white border-b hover:bg-gray-100"
                    onClick={() => {
                        selectFileById(file._id);
                    }}
                >
                    <td className="px-6 py-4 flex justify-center">
                        <CheckBox id={"file-" + file._id} onclick={changeSelection}/>
                    </td>
                    <th
                        scope="row"
                        className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
                    >
                        {file.name}
                    </th>
                    <td className="px-6 py-4">{getIconForType(file.type)}</td>
                    <td className="px-6 py-4">{file.school.name}</td>
                    <td className="px-6 py-4">{file.sizeText}</td>
                    <td className="px-6 py-4">
                        {moment(file.updated_at ?? new Date()).format("H:mm D MMM Y")}
                    </td>
                </tr>
            );
        });
        return nodeList;
    }

    const selectAll = (ev) => {
        const isChecked = ev.target.checked;
        fileList.forEach((file) => {
            document.getElementById("file-" + file._id).checked = isChecked;
        });
        changeSelection();
    };

    const getSelectedFiles = () => {
        const allFilesNode = Array.from(
            document.querySelectorAll('[id^="file-"]:checked')
        );
        return allFilesNode.map((file) => {
            return (
                fileList.filter((lsF) => lsF._id === file.id.replace("file-", ""))[0] ??
                null
            );
        });
    };

    const changeSelection = () => {
        setIsMultipleFileSelected(getSelectedFiles().length > 1);
        setIsMenuShown(getSelectedFiles().length > 0);
    };

    const toggleMenu = () => {
        isMenuOpen ? closeMenu() : openMenu();
    };

    const openMenu = () => {
        setIsMenuOpen(true);
        window.addEventListener("click", closeMenu, true);
    };

    function closeMenu() {
        setIsMenuOpen(false);
        window.removeEventListener("click", closeMenu, true);
    }

    function handleLocalDelete() {
        const idSelectedFiles = getSelectedFiles().map((file) => file._id);
        // console.log(idSelectedFiles);
        handelDelete(
            "files",
            "Suppression du ou des fichier(s)",
            "Êtes-vous sûr(e) de vouloir supprimer ce ou ces fichier(s) ?",
            idSelectedFiles,
            fileList,
            setFileList,
            () => {
                setForceUpdate(!forceUpdate)
            }
        );
        setForceUpdate(!forceUpdate);
    }

    const showModal = () => {
        const argsForComponent = {
            objectToModify: {objectType: "files"}
        };

        dispatch(setComponent("Formulaire"));
        dispatch(setArgsForComponent(argsForComponent));
        dispatch(setTitle(`Ajouter un fichier`));
        dispatch(display());
    };

    function getMenuItems() {
        return isMultipleFileSelected
            ? [
                {
                    name: "Supprimer",
                    type: "delete",
                    onClick: handleLocalDelete,
                },
                // {
                //     name: "Dupliquer",
                //     onClick: () => {
                //         console.log("DUP")
                //     }
                // },
            ]
            : [
                {
                    name: "Supprimer",
                    type: "delete",
                    onClick: handleLocalDelete,
                },
                // {
                //     name: "Modifier",
                //     onClick: () => {
                //         console.log("Modifier");
                //     }
                // },
                // {
                //     name: "Dupliquer",
                //     onClick: () => {
                //     }
                // },
            ];
    }

    const addHandler = () => {
        showModal();
    }

    return (
        <>
            {/*<button onClick={() => {*/}
            {/*    SendNotification()*/}
            {/*}}>CCC*/}
            {/*</button>*/}
            <div className="relative overflow-x-auto overflow-y-visible shadow-md sm:rounded-lg">
                <table className="w-full text-sm text-left text-gray-500">
                    <thead className="text-xs text-gray-900 bg-gray-200">
                    <tr>
                        <th colSpan={5}></th>
                        <th scope="col">
                            <div className="relative inline-block text-left w-full">
                                <div className="flex justify-end">
                                    <div
                                        onClick={addHandler}
                                        className="align-middle pr-6 pt-4 text-sm font-semibold flex justify-end cursor-pointer"
                                    >
                                        <img
                                            className="h-6 w-6"
                                            src={process.env.PUBLIC_URL + "/assets/images/add.svg"}
                                            alt=""
                                        />
                                    </div>
                                    {isMenuShown &&
                                        <div className="pr-6 pt-4 flex flex-col justify-center">
                                            <ButtonMenu
                                                menuPosition="bottom"
                                                menuItems={getMenuItems()}
                                            />
                                        </div>}

                                </div>
                            </div>
                        </th>
                    </tr>
                    <tr className="uppercase">
                        <th scope="col" className="px-6 py-3 flex justify-center">
                            <CheckBox id="allSelector" onclick={selectAll}/>
                        </th>
                        <th scope="col" className="px-6 pb-3">
                            Nom du fichier
                        </th>
                        <th scope="col" className="px-6 pb-3">
                            Type
                        </th>
                        <th scope="col" className="px-6 pb-3">
                            Ecole
                        </th>
                        <th scope="col" className="px-6 pb-3">
                            Taille
                        </th>
                        <th scope="col" className="px-6 pb-3">
                            Dernière mise à jour
                        </th>
                    </tr>
                    </thead>
                    <tbody>{generateListedFiles(fileList)}</tbody>
                </table>
            </div>
        </>
    );
}

export default AmbassadorAttachments;
