// REDUX
import {store} from "../../store";
import {setUserInfos} from "../../store/userInfos/userInfosSlice";

// MODULES NECESSAIRES
import {hasMinimalAccess} from "../AccessManager/AccessManager";

// API CALL
import {fetchAPIwithToken} from "../API";
import {setBothToken} from "../../store/tokens/tokens";

// CONSTANTES
const BASE_URL = process.env.REACT_APP_API_URL;
const AUTH_URL = `${BASE_URL}auth/`;
const API_URL = `${BASE_URL}api/`;

// TYPE

// Authentification de l'utilisateur
export async function login(email = null, password = null) {
    try {
        const data = {username: email, password: password};

        const result = await fetchAPIwithToken(
            AUTH_URL + "login",
            null,
            "POST",
            data
        );

        if (result.ok) {
            if (hasMinimalAccess(result?.result?.user.roles)) {
                const objectUserInfo = {...result?.result?.user};
                await store.dispatch(setUserInfos(objectUserInfo));
                await store.dispatch(setBothToken({
                    accessToken: result?.tokens.accessToken,
                    refreshToken: result?.tokens.refreshToken,
                    permanentToken: result?.tokens.permanentToken
                }));

                return {error: false, message: "Vous êtes connecté !"};
            } else
                return {
                    error: true,
                    message: "Vous n'avez pas les droites requis pour être ici.",
                };
        } else {
            return {error: true, message: "Vos identifiants n'existent pas."};
        }
    } catch (error) {
        console.error(error);
        return {
            error: true,
            message:
                "Une erreur est survenue lors de l'accès à l'API d'authentification.",
        };
    }
}

// Récupération de la liste des utilisateurs pour l'application
export async function getUsers() {
    try {
        const result = await fetchAPIwithToken(
            API_URL + "users",
            `Ambassador ${store.getState().tokens.accessToken}`
        );
        return result?.result;
    } catch (error) {
        console.error(error);
        return {
            error: true,
            message: "Une erreur est survenue lors de l'accès à l'API.",
        };
    }
}

export async function getUser(id) {
    try {
        const result = await fetchAPIwithToken(
            API_URL + "users/" + id,
            `Ambassador ${store.getState().tokens.accessToken}`
        );
        return result?.result;
    } catch (error) {
        console.error(error);
        return {
            error: true,
            message: "Une erreur est survenue lors de l'accès à l'API.",
        };
    }
}

export async function getUsersWithStats() {
    try {
        const result = await fetchAPIwithToken(
            API_URL + "users/with-stats",
            `Ambassador ${store.getState().tokens.accessToken}`
        );
        return result?.result;
    } catch (error) {
        console.error(error);
        return {
            error: true,
            message: "Une erreur est survenue lors de l'accès à l'API.",
        };
    }
}

export async function insertUser(userData) {
    try {
        const result = await fetchAPIwithToken(
            API_URL + "users",
            `Ambassador ${store.getState().tokens.accessToken}`,
            "POST", userData
        );
        return result?.result;
    } catch (error) {
        console.error(error);
        return {
            error: true,
            message: "Une erreur est survenue lors de l'accès à l'API.",
        };
    }
}

export async function updateUser(idToUpdate, userData) {
    try {
        const result = await fetchAPIwithToken(
            API_URL + "users/" + idToUpdate,
            `Ambassador ${store.getState().tokens.accessToken}`,
            "PUT", userData
        );
        return result?.result;
    } catch (error) {
        console.error(error);
        return {
            error: true,
            message: "Une erreur est survenue lors de l'accès à l'API.",
        };
    }
}
