"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Base = void 0;
var requestsManager_1 = require("../requestsManager");
var DynamicClass_1 = require("../ObjectTypes/DynamicClass");
var Base = /** @class */ (function () {
    function Base(params) {
        var table = (params !== null && params !== void 0 ? params : {}).table;
        var tableName = this.constructor.name.charAt(0).toLowerCase() + this.constructor.name.slice(1);
        this.table = table !== null && table !== void 0 ? table : tableName;
    }
    Base.prototype.getAll = function () {
        var linkedObject = this;
        return (0, requestsManager_1.getAPI)({ objectType: this.table })
            .then(function (response) {
            linkedObject.allMinData = response;
            return response;
        });
    };
    Object.defineProperty(Base.prototype, "all", {
        get: function () {
            return this.allMinData;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Base.prototype, "selectList", {
        get: function () {
            var _a;
            return (_a = this.allMinData) === null || _a === void 0 ? void 0 : _a.map(function (obj) { return obj.forLabel; });
        },
        enumerable: false,
        configurable: true
    });
    Base.prototype.getById = function (id) {
        (0, requestsManager_1.getAPI)({ objectType: this.table, options: "/" + id })
            .then(function (response) { return response; });
    };
    Base.prototype.getWithParams = function (params, values) {
        var linkedObject = this;
        return (0, requestsManager_1.getAPI)({
            objectType: this.table,
            options: "/" + params + (values ? "/" + values.join("/") : ""),
        }).then(function (response) { return linkedObject.handleApiReturn(response, false); });
    };
    Base.prototype.save = function (obj) {
        var linkedObject = this;
        return (0, requestsManager_1.postAPI)({
            objectType: this.table,
            dataToTransfert: obj
        }).then(function (response) { return linkedObject.handleApiReturn(response); });
    };
    Base.prototype.update = function (idToModify, obj) {
        var linkedObject = this;
        return (0, requestsManager_1.putAPI)({
            objectType: this.table,
            dataToTransfert: obj,
            idToModify: idToModify
        }).then(function (response) { return linkedObject.handleApiReturn(response); });
    };
    Base.prototype.handleApiReturn = function (response, singleReturn) {
        var _this = this;
        var _a, _b, _c;
        if (singleReturn === void 0) { singleReturn = true; }
        if (!((_a = response === null || response === void 0 ? void 0 : response.error) !== null && _a !== void 0 ? _a : false)) {
            if (singleReturn)
                return new DynamicClass_1.DynamicClass(this.table, response);
            else if (Array.isArray(response))
                return response.map(function (apiObj) {
                    return new DynamicClass_1.DynamicClass(_this.table, apiObj);
                });
            else
                return null;
        }
        else {
            throw new Error((_c = "Error message :" + ((_b = response === null || response === void 0 ? void 0 : response.msg) !== null && _b !== void 0 ? _b : response.message)) !== null && _c !== void 0 ? _c : " an error has occurred while fetching the API");
        }
    };
    return Base;
}());
exports.Base = Base;
