import React from 'react';

function User({primary, secondary}) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            data-name="Calque 1"
            viewBox="0 0 338.42 406.48"
            width={40}
            height={40}
        >
            <path
                fill={"#a1a1a1"}
                d="M185.22 0c22.9 8.26 43.88 19 59.27 39.09 23.72 30.93 26.14 75.69 5 109.93a98.59 98.59 0 0 1-105.31 44c-37.57-8.47-68.63-42-73.82-79.79-7.07-51.46 21.44-95.42 71.27-110 3-.87 5.81-2.18 8.71-3.29Z"/>
            <path
                fill={"#a1a1a1"}
                d="M168.73 405.94c-31.68 0-63.36.26-95-.07-48.64-.5-75-28.33-73.66-76.75.84-30.31 4.84-59.86 18.4-87.46 10.78-22 27-37.15 51.38-43.14 13.52-3.33 25.72-2.71 37.43 5.78 40.72 29.55 81.44 29.39 123 .76 22.73-15.66 54.86-8.37 75.86 16.19 21.53 25.18 27.59 56.26 31.14 87.95 8.38 74.89-28.7 100.41-89.34 97-26.35-1.5-52.81-.26-79.21-.26Z"/>
            <path
                d="M905.92 478.1c-1.06 41.62-34 72.5-76.36 71.48-40.79-1-72-35.11-70.59-77.26 1.3-39.46 34.78-70.81 74.78-70 41.62.8 73.25 34.01 72.17 75.78Z"
                fill={primary}
                transform="translate(-665.98 -378.21)"
            />
            <path
                fill={secondary}
                d='M834.53 760.33c-29.58 0-59.17.12-88.75 0-43-.2-57.72-14.79-55.89-57.4 1.29-30.13 4.85-60.08 24.36-85.18 15.74-20.26 33-23.31 54.33-9.8q67 42.5 133.63-.53c19.6-12.61 38.08-10.28 52.11 8.67 25.37 34.26 29.78 73.71 23.48 114.43-2.63 17-17.38 28.09-37.13 28.68-35.35 1.05-70.75.3-106.13.3Z'
                transform="translate(-665.98 -378.21)"
            />
        </svg>
    );
}

export default User;
