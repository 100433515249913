export function testEventName(eventName) {
    if (eventName) {
        const toCompare = eventName.toLowerCase();
        if (toCompare.match(/(jpo)|(journée portes ouvertes)/)) {
            return "#4a7ccd";
        }
        if (toCompare.match(/(forum)/)) {
            return "#63bbff";
        }
        if (toCompare.match(/(salon)/)) {
            return "#726fc6";
        }
        if (toCompare.match(/(soirée)/)) {
            return "#00a7d9";
        }
    }
    return "#0086CC";
};
