// IMPORTATION REDUX
import {useSelector} from "react-redux";

// NAVIGATION
import {useLocation} from "react-router-dom";

// IMPORTATION DES ROUTES
import {getNameByRoutePath, getRoutePathByName} from "../routes/routes";

function NoPage() {
    const location = useLocation();
    const userIsLoggedIn =
        useSelector((state) => state.tokens.accessToken) !== null;
    if (userIsLoggedIn) {
        window.location = getRoutePathByName(getNameByRoutePath(window.location.pathname ?? "dashboard"));
    } else if (location.pathname === "/") {
        window.location = getRoutePathByName("login");

    } else {
        window.location = getRoutePathByName('login') + "?afterTo=" + getNameByRoutePath(window.location.pathname);
    }
}

export default NoPage;
