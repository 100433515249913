import React, {useEffect, useState} from "react";
// REDUX
import {useSelector} from "react-redux";

// API CALL
import SmallCard from "../components/SmallCard/SmallCard";
import MediumCard from "../components/MediumCard/MediumCard";

// BUTTONS
import ButtonAdd from "../components/Buttons/ButtonAdd";

import {faUser} from "@fortawesome/free-solid-svg-icons";

// COMFIRMATION BOX
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import {handelDelete} from "../hooks/handleDelete";
import openForm from "../components/Formulaire/openForm";
import TopGridDisplay from "../components/TopGridDisplay/TopGridDisplay";

import {Users as UsersRepo} from "../services/API/Repositories/Users";
import {Schools} from "../services/API/Repositories/Schools";
import {Roles as RolesRepos} from "../services/API/Repositories/Roles";

const repoUsers = new UsersRepo();
const repoSchools = new Schools();
const repoRoles = new RolesRepos();

function Users() {
    const parameterForm = {
        firstname: {label: "Prénom", placeholder: "Prénom"},
        lastname: {label: "Nom", placeholder: "Nom"},
        email: {label: "E-Mail", placeholder: "E-Mail"},
        tel: {label: "Téléphone", placeholder: "Téléphone"},
        school: {
            label: "Ecole",
            placeholder: "Ecole",
            type: "select-one",
            options: [],
        },
        roles: {
            label: "Rôles",
            placeholder: "Rôles",
            type: "select-multiple",
            options: [],
        },
        isValid: {
            label: "Profile validé",
            placeholder: "Profile validé",
            type: "checkbox",
        },
        computerPorts: {
            label: "Ports",
            placeholder: "Ports",
            type: "select-multiple",
            options: [
                {label: "HDMI", value: "hdmi"},
                {label: "VGA", value: "vga"},
                {label: "Type C", value: "typeC"}
            ],
        },
        tShirtSize: {
            label: "Taille T-Shirt",
            placeholder: "Taille T-Shirt",
            type: "select-one",
            options: [
                {value: "XS", label: "XS"},
                {value: "S", label: "S"},
                {value: "M", label: "M"},
                {value: "L", label: "L"},
                {value: "XL", label: "XL"},
                {value: "XXL", label: "XXL"}
            ],
        },
        gender: {
            label: "Genre",
            placeholder: "Genre",
            type: "select-one",
            options: [
                {value: 1, label: "Femme"},
                {value: 2, label: "Homme"},
                {value: 3, label: "Intersexe"}
            ]
        },
        schoolYear: {
            label: "Année",
            placeholder: "Année",
            type: "select-one",
            options: [
                {value: 1, label: "N1"},
                {value: 2, label: "N2"},
                {value: 3, label: "N3"},
                {value: 4, label: "N4"},
                {value: 5, label: "N5"}
            ]
        },
        isEnable: {
            label: "Compte activé",
            placeholder: "Compte activé",
            type: "checkbox",
        }
    };

    const paramsOpenModal = {
        parameters: parameterForm,
        objectType: "users",
    };

    repoRoles.getAll().then((roles) => {
        paramsOpenModal.parameters.roles.options = roles.map((role) => {
            return {label: role.name, value: role._id};
        });
    });

    repoSchools.getAll().then(
        (schools) =>
            (paramsOpenModal.parameters.school.options = schools.map((school) => {
                return {label: school.name, value: school._id};
            }))
    );

    const [userList, setUserList] = useState([]);

    const isModalOpen = useSelector((state) => state.displayModal.isDisplayed);

    useEffect(() => {
        repoUsers.getWithParams("with-stats").then((users) => {
            setUserList(users);
        });
    }, [isModalOpen]);

    const localHandelDelete = (userToDelete) => {
        handelDelete(
            "users",
            `${userToDelete.lastname} ${userToDelete.firstname}`,
            "Êtes-vous sûr de vouloir supprimer cette utilisateur ?",
            userToDelete,
            userList,
            setUserList
        );
    };

    const newUser = {
        lastname: "",
        firstname: "",
        email: "",
        tel: "",
        school: {},
        roles: {},
    };

    function generateListedUsers(users) {
        let nodeList = [];
        users.forEach((user) => {
            const {
                _id,
                firstname,
                lastname,
                email,
                tel,
                school,
                roles,
                schoolYear,
                gender,
                tShirtSize,
                computerPorts,
                isValid,
                isEnable
            } = user;
            const optiUser = {
                _id, firstname, lastname, email, tel, school, roles, schoolYear,
                gender,
                tShirtSize,
                computerPorts,
                isValid,
                isEnable
            };
            nodeList.push(
                <MediumCard
                    icon={faUser}
                    key={user._id}
                    titre={user.lastname + " " + user.firstname}
                    textes={[
                        user.school.name ?? "",
                        (user.stats.nb_eventdone ?? 0) + " évènement(s) fait",
                        (user.stats.nb_formsended ?? 0) + " formulaires remplis",
                    ]}
                    menu={[
                        {
                            name: "Supprimer",
                            link: "#",
                            type: "delete",
                            onClick: () => {
                                localHandelDelete(user);
                            },
                        },
                        {
                            name: "Modifier",
                            link: "#",
                            type: "normal",
                            onClick: () =>
                                openForm({
                                    ...paramsOpenModal,
                                    objectToModify: optiUser,
                                    title: user.lastname + " " + user.firstname,
                                }),
                        },
                    ]}
                />
            );
        });
        return nodeList;
    }

    return (
        <div>
            <TopGridDisplay>
                <SmallCard
                    texte="Utilisateurs"
                    keyFigure={userList.length}
                    icon={faUser}
                />
            </TopGridDisplay>
            <div className="flex flex-row justify-end -mb-12 -translate-y-12">
                <ButtonAdd
                    onclick={() => {
                        openForm({
                            ...paramsOpenModal,
                            objectToModify: newUser,
                            title: "Nouvel utilisateur",
                            isNew: true,
                        });
                    }}
                />
            </div>
            <TopGridDisplay>{generateListedUsers(userList)}</TopGridDisplay>
        </div>
    );
}

export default Users;
