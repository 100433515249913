import React from 'react';
import {Outlet} from "react-router-dom";

function ContainerAskForHelp(props?: {
    children: React.ReactNode
}) {
    return (
        <div className={"p-4 bg-gray-100 dark:bg-gray-900 h-screen"}>
            <div
                className="relative overflow-x-auto shadow-md sm:rounded-lg w-full text-sm text-left text-gray-500 dark:text-gray-400">
                {props?.children ?? null}
                <Outlet/>
            </div>
        </div>
    );
}

export default ContainerAskForHelp;
