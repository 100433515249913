import React from 'react';

function House({primary, secondary}) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlSpace="preserve"
            id="Calque_1"
            x={0}
            width={40}
            height={40}
            y={0}
            style={{
                enableBackground: "new 0 0 508.8 509.2",
            }}
            viewBox="0 0 508.8 509.2"
        >
            <path
                fill={"#a1a1a1"}
                d="M508.8 265.3c-3.5 10.4-8.6 19.7-17.6 26.4-8.7 6.8-19.6 10.1-30.7 9.4-4.7-.3-5.6 1.4-5.6 5.7.1 48.5.2 96.9.1 145.4 0 27.7-16.3 49-42.2 55.5-4.7 1.1-9.5 1.6-14.3 1.6h-77.7c-11.2 0-17.1-6-17.1-17.3 0-38.2-.4-76.3.2-114.5.3-16.5-11.2-29-28.5-28.4-13.9.5-27.9.1-41.8.1-17.7 0-27.8 10-27.9 27.7v114c0 12.9-5.5 18.4-18.5 18.4h-75.7c-33.4 0-57-23.6-57.1-56.9-.1-48.5-.1-96.9.1-145.4 0-4.9-1.3-5.9-6-5.8-28.5.1-49.7-21.4-48.4-49.3.4-10.4 4.4-20.3 11.2-28.1 1.4-1.6 2.9-3.2 4.5-4.7 67.9-68 135.9-135.9 203.8-203.9 4.2-4.2 8.8-8.1 14.2-10.8 14.9-7.4 40.5-6.1 53.6 8 7 7.5 14.5 14.6 21.8 21.8C370.8 95.9 432.4 157.5 494.1 219c6.8 6.8 11.8 15.1 14.7 24.3v22z"/>
            <path
                d="M84.5 371.2v-82.1c0-11.9-5.8-17.7-17.7-17.7H47.4c-7.6 0-13.2-3.4-16.2-10.3-3.1-7.1-1.5-13.7 4-19.2 8.2-8.3 16.4-16.4 24.6-24.6L240.1 37c10.6-10.6 19.1-10.5 29.8.2 67.2 67.2 134.5 134.4 201.7 201.6 5.8 5.8 10.2 11.8 7.4 20.6-2 6.9-8.3 11.7-15.6 11.9-7.1.2-14.3 0-21.4.1-10.9.1-16.9 6.1-16.9 17v162.8c0 18.3-9.9 28.2-28.2 28.3-19.6 0-39.2-.1-58.7.1-3.7 0-4.7-.8-4.7-4.6.2-33 .2-66 .1-99.1-.1-27.8-17.2-49.4-43.7-55.2-2.8-.6-5.6-1-8.4-1-18.7-.3-37.5-1.3-56.2.3-27.7 2.3-49.1 25.4-49.2 53.2-.4 33.7-.2 67.4 0 101 0 4.5-1.2 5.4-5.5 5.3-19.7-.2-39.5-.1-59.2-.1-16.6 0-26.9-10.4-26.9-27v-81.2z"
                fill={primary}
            />
            <path
                d="M84.5 371.2v-41.1c0-40.9-.1-40.7 0-41.1 1.8-7.2 192.5-50.3 229.5-59 37.3-8.9 109.7-28.5 139.6-10.2 8.1 5 24.9 20.1 26.3 32.5.1 1.2.2 3.9-.7 7.1-2 6.9-8.3 11.7-15.6 11.9-7.1.2-14.3 0-21.4.1-10.9.1-16.9 6.1-16.9 17v162.8c0 18.3-9.9 28.2-28.2 28.3-19.6 0-39.2-.1-58.7.1-3.7 0-4.7-.8-4.7-4.6.2-33 .2-66 .1-99.1-.1-27.8-17.2-49.4-43.7-55.2-2.8-.6-5.6-1-8.4-1-18.7-.3-37.5-1.3-56.2.3-27.7 2.3-49.1 25.4-49.2 53.2-.4 33.7-.2 67.4 0 101 0 4.5-1.2 5.4-5.5 5.3-19.7-.2-39.5-.1-59.2-.1-16.6 0-26.9-10.4-26.9-27-.2-27.1-.2-54.2-.2-81.2z"
                fill={secondary}
            />
        </svg>
    );
}

export default House;
