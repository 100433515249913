import React, {useRef} from 'react';
import {Editor} from "@tinymce/tinymce-react";
import useColors from "../../hooks/useColors";
import {Colors} from "../../services/API/ObjectTypes/Colors";

const emptyFunc = (): void => {
};

type Params = {
    defaultContent?: string,
    idMail: string,
    onChange: (newVal: string) => void,
    setCurrentContent: (content: string) => void,
}

function WYSIWYG({idMail = "123", defaultContent = "", onChange = emptyFunc, setCurrentContent = emptyFunc}: Params) {
    const editorRef = useRef<any>(null);
    const getContentIn = () => {
        if (editorRef.current) {
            return editorRef.current.getContent();
        }
        return "";
    };

    const {colors, isLoading} = useColors();
    const colorsSet = colors.map((color: Colors) => Object.entries(color.colors ?? {}).map(([name, colors]) => [colors.replace("#", "").toUpperCase(), color.name + " " + name]).flat()).flat();

    if (isLoading) {
        return <>CHARGEMENT</>
    }

    return (
        <div>
            <Editor
                onFocusIn={() => {

                }}
                onEditorChange={() => {
                    onChange(getContentIn());
                    setCurrentContent(getContentIn());
                }}
                apiKey={process.env.REACT_APP_TINY_CLOUD_KEY}
                onInit={(_, editor) => editorRef.current = editor}
                initialValue={defaultContent}
                onDirty={() => onChange(getContentIn())}
                id={"mail-" + idMail}
                init={{
                    height: 600,
                    menu: {
                        edit: {title: 'Edit', items: 'undo, redo, selectall'}
                    },
                    plugins: [
                        'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                        'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                        'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'
                    ],
                    toolbar: 'undo redo | blocks | ' +
                        'bold italic underline strikethrough forecolor hr  | align lineheight | alignleft aligncenter ' +
                        'alignright alignjustify | emoticons image link lists media searchreplace | bullist numlist outdent indent | ' +
                        'removeformat | help',
                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                    color_map: colorsSet,
                }}
            />
        </div>
    );
}


export default WYSIWYG;
