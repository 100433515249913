import React, {useEffect, useState} from "react";
import {Schools} from "../../services/API/Repositories/Schools";

const SchoolRepo = new Schools();
export default function ColorScoolSelect(props) {
    const {setPrimaryColorSelected, setSecondaryColorSelected, setSchoolId} = props;
    const [selectedSchool, setSelectedSchool] = useState("NEXT-U");
    const [schoolColors, setSchoolColors] = useState({
        WebTech: {primary: "#0086cc", secondary: "#ffc229"},
        ATLAS: {primary: "#0086cc", secondary: "#ffc229"},
        ESCEN: {primary: "#0086cc", secondary: "#ffc229"},
        Bachelor: {primary: "#0086cc", secondary: "#ffc229"},
        Magnum: {primary: "#0086cc", secondary: "#ffc229"},
    });

    useEffect(() => {
        SchoolRepo.getAll().then(schools => {
            const obj = {};
            schools.map(school => {
                obj[school?.name] = {...school?.colors, schoolId: school._id};
            });
            setSchoolColors(obj);
        })
    }, []);

    const handleSchoolSelection = (schoolName) => {
        setSelectedSchool(schoolName);
        setPrimaryColorSelected(schoolColors[schoolName].primary);
        setSecondaryColorSelected(schoolColors[schoolName].secondary);
        setSchoolId(schoolColors[schoolName].schoolId);
    };

    return (
        <>
            <div className="flex flex-row justify-evenly">
                {Object.keys(schoolColors).map((schoolName) => (
                    <div
                        key={schoolName}
                        className={`${
                            selectedSchool === schoolName ? "bg-sky-800 shadow-lg" : "bg-blue-400"
                        } p-2 rounded-lg hover:bg-sky-700 cursor-pointer`}
                        onClick={() => handleSchoolSelection(schoolName)}
                    >
                    <span className={`${selectedSchool === schoolName ? "text-white" : "text-black"
                    } text-2xl text-center`}
                    >{schoolName}</span>
                    </div>
                ))}
            </div>
            <div className="inline-flex items-center justify-center w-full">
                <hr className="w-64 h-px my-8 bg-gray-200 border-0 dark:bg-gray-700"/>
            </div>
        </>
    );
}
