import React, {useEffect, useState} from "react";
import WYSIWYG from "../components/WYSIWYG/WYSIWYG";
import Select from "react-select";

import makeAnimated from "react-select/animated";
import clsx from "clsx";
import useMail from "../hooks/useMail";
import {useAppSelector} from "../store/hooks";

const animatedComponents = makeAnimated();

function CustomizationMail() {
    const {mails, repo, isLoading} = useMail();

    const userInfos = useAppSelector(state => state.userInfos);

    const mailsToModify = repo.selectList;

    useEffect(() => {
        if (!isLoading) {
            setMailContent(mails.reduce((a, v) => ({
                ...a,
                [v?._id?.toString() ?? 0]: v.mailBody
            }), {}));

            setMailSubject(mails.reduce((a, v) => ({
                ...a,
                [v?._id?.toString() ?? 0]: v.subject
            }), {}));
        }
    }, [isLoading])

    const [mailContent, setMailContent] = useState<any>({});

    const [mailSubject, setMailSubject] = useState<any>({});

    const [mailDisplayed, setMailDisplayed] = useState(mailsToModify?.[0]);
    const [hasChanged, setHasChanged] = useState<boolean>(false);
    const [hasBeenPrevent, setHasBeenPrevent] = useState<boolean>(false);
    const [currentMailContent, setCurrentMailContent] = useState<string>("");
    const [currentSubject, setCurrentSubject] = useState<string>("");
    const [canUpdate, setCanUpdate] = useState(true);
    // TEST MAIL
    const [testMail, setTestMail] = useState(userInfos.email);

    const getSelectedMail = () => {
        return mails.find(mail => mail._id === mailDisplayed?.value);
    }

    const saveCurrentContent = () => {
        const mailToModify = getSelectedMail();

        if (mailToModify) {
            mailToModify.subject = currentSubject;
            mailToModify.mailBody = currentMailContent;
            mailToModify.save()
                .then(() => {
                    setMailContent((olVal: typeof mailContent) => ({
                        ...olVal,
                        [mailDisplayed?.value ?? 0]: currentMailContent
                    }))
                    setMailSubject((oldVal: typeof mailSubject) => ({
                        ...oldVal,
                        [mailDisplayed?.value ?? 0]: currentSubject
                    }))
                    setHasChanged(false);
                    setHasBeenPrevent(false);
                })
                .catch(console.error);
        } else {
            alert("Une erreur est survenue lors de l'enregistrement.");
        }
    }

    const sendTestMail = () => {
        const mailToModify = getSelectedMail();
        if (mailToModify)
            mailToModify.sendTest(testMail).then(resp => console.log(resp)).catch(console.error);
    }

    return (
        <div className={"p-1"}>
            <div className="flex flex-col">
                <h2 className="text-xl">Mail à modifier</h2>
                <Select
                    onFocus={() => {
                        if (hasChanged && !hasBeenPrevent) {
                            window.alert("Attention Toutes les modifications faites ne sont pas enregistrée.");
                            setHasBeenPrevent(true);
                        }
                    }}
                    onChange={(newVal: typeof mailDisplayed) => {
                        setMailDisplayed((oldVal: typeof mailDisplayed) => {
                            if (oldVal?.value !== newVal?.value) {
                                setHasChanged(false);
                                setHasBeenPrevent(false);
                                setCanUpdate(false);
                                setCurrentSubject(mailSubject[newVal?.value ?? 0]);
                                setTimeout(() => setCanUpdate(true), 500);
                            }
                            return newVal
                        });
                    }}
                    closeMenuOnSelect={true}
                    components={animatedComponents}
                    defaultValue={mailDisplayed}
                    isMulti={false}
                    name={"mail-selector"}
                    id={"mail-selector"}
                    options={mailsToModify}
                    className={"basic-multi-select mb-8"}
                    classNamePrefix="select"
                    styles={{
                        container: (baseStyles) => ({
                            ...baseStyles,
                            zIndex: 100,
                        }),
                        control: (baseStyles) => ({
                            ...baseStyles,
                            borderColor: "rgb(209 213 219)",
                        }),
                    }}
                    placeholder={"Choisir le mail à modifier"}
                />
            </div>

            <div className="flex justify-between mb-2 items-center gap-10">
                <div className="flex flex-1">
                    <span
                        className="inline-flex items-center px-3 text-sm text-gray-900 bg-gray-200 border border-r-0 border-gray-300 rounded-l-lg">
                        Sujet
                    </span>
                    <input
                        type={"text"}
                        id={"mail-subject"}
                        placeholder={"Sujet du mail"}
                        onChange={(newVal) => {
                            setHasBeenPrevent(false);
                            setHasChanged(true);
                            setCurrentSubject(newVal.target.value);
                        }}
                        // value={mailSubject[mailDisplayed?.value ?? 0]}
                        value={currentSubject}
                        className={clsx("bg-gray-50 border border-l-0 h-fit text-gray-900 text-sm rounded-r-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 border-gray-300")}
                    />
                </div>
                <button
                    onClick={saveCurrentContent}
                    className={clsx("bg-white text-gray-800 font-semibold py-2 px-4 border border-gray-400 rounded shadow", !hasChanged ? "cursor-not-allowed opacity-50" : "hover:bg-gray-100")}
                    disabled={!hasChanged}>
                    Enregistrer
                </button>
            </div>

            <div className="flex gap-4 bg-white rounded-lg mt-5 mb-3">
                <div className="bg-gray-200 px-4 py-2 rounded-l-lg">Variables disponibles</div>
                <div className="py-2 flex gap-4">
                    {getSelectedMail()?.variables.map(variable => {
                        return (<div key={"variables" + variable}>{variable}</div>)
                    })
                    }
                </div>
            </div>
            <WYSIWYG idMail={"mot-de-passe-change"} defaultContent={mailContent[mailDisplayed?.value ?? 0]}
                     onChange={() => {
                         if (canUpdate) {
                             setHasChanged(true);
                             setHasBeenPrevent(false);
                         }
                     }}
                     setCurrentContent={setCurrentMailContent}
            />

            <div className="flex flex-col mt-8">
                <h3 className={"text-xl"}>Tester le mail</h3>
                <div className="flex gap-10">
                    <div className="flex flex-1">
                    <span
                        className="inline-flex items-center px-3 text-sm text-gray-900 bg-gray-200 border border-r-0 border-gray-300 rounded-l-lg min-w-max">
                        Envoyer à
                    </span>
                        <input
                            type={"text"}
                            id={"mail-target"}
                            placeholder={"Destinataires"}
                            onChange={(val) => setTestMail(val.target.value)}
                            value={testMail}
                            className={clsx("bg-gray-50 border border-l-0 h-fit text-gray-900 text-sm rounded-r-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 border-gray-300")}
                        />
                    </div>
                    <button
                        onClick={sendTestMail}
                        className={clsx("bg-white text-gray-800 font-semibold py-2 px-4 border border-gray-400 rounded shadow", !mailDisplayed?.value ? "cursor-not-allowed opacity-50" : "hover:bg-gray-100")}
                        disabled={!mailDisplayed?.value}>
                        Envoyer le mail de test
                    </button>
                </div>
            </div>

        </div>
    );
}

export default CustomizationMail;
