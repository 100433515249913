import React, {useEffect, useState} from "react";
import SmallCard from "../components/SmallCard/SmallCard";
import TopGridDisplay from "../components/TopGridDisplay/TopGridDisplay";
import {Events} from "../services/API/Repositories/Events";
import {Answers} from "../services/API/Repositories/Answers";
import {faCalendarAlt} from "@fortawesome/free-solid-svg-icons";

function Dashboard(userName = "USER") {
    const [gotEventToday, setGotEventToday] = useState(null);
    const [formsToday, setFormsToday] = useState([]);
    const [formsPerAmbassador, setFormsPerAmbassador] = useState(0);
    const [futureEvents, setFutureEvents] = useState(0);
    const [eventThisYear, setEventThisYear] = useState(0);

    const startSchoolYear = new Date();
    startSchoolYear.setMonth(-4);
    const today = new Date();
    const todayMorning = new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDay()
    );

    useEffect(() => {
        // GET NEXT EVENTS
        (new Events()).getWithParams("between", [startSchoolYear.toISOString()]).then(events => {
            const tomorrow = new Date(todayMorning);
            tomorrow.setDate(tomorrow.getDate() + 1);
            setFutureEvents(events.filter(event => new Date(event.start_date) > todayMorning).length);
            setEventThisYear(events.filter(event => new Date(event.start_date) <= todayMorning).length);
            setGotEventToday(events.filter(event => new Date(event.start_date) >= todayMorning && new Date(event.start_date) < tomorrow)?.[0] ?? null);
        });

        // GET FORMS
        (new Answers()).getWithParams("between", [todayMorning.toISOString()]).then(answers =>
            setFormsToday(answers.length)
        );

    }, []);

    useEffect(() => {
        if (gotEventToday !== null) {
            let totalAmb = 0;
            Object.entries(gotEventToday.delegate_per_school).forEach(([_, val]) => {
                totalAmb += val?.ambassadors?.length ?? 0;
            })
            if (totalAmb > 0) {
                setFormsPerAmbassador(formsToday / totalAmb);
            } else {
                setFormsPerAmbassador(formsToday)
            }
        }
    }, [formsToday, gotEventToday]);

    return (
        <>
            <TopGridDisplay>
                {gotEventToday !== null ? (
                    <>
                        <SmallCard
                            texte="Formulaire(s) remplis aujourd'hui"
                            keyFigure={formsToday}
                            isEventDay={true}
                        />
                        <SmallCard
                            texte="Formulaire(s) / Ambassador"
                            keyFigure={formsPerAmbassador}
                            isEventDay={true}
                        />
                    </>
                ) : null}
                <SmallCard
                    texte="Évènement(s) à venir"
                    keyFigure={futureEvents}
                    isEventDay={false}
                    icon={faCalendarAlt}
                />
                <SmallCard
                    texte="Évènement(s) fait cette année"
                    keyFigure={eventThisYear}
                    isEventDay={false}
                    icon={faCalendarAlt}
                />
            </TopGridDisplay>
        </>
    );
}

export default Dashboard;
