import {useEffect, useState} from 'react';
import {User} from "../services/API/ObjectTypes/User";
import {Users} from "../services/API/Repositories/Users";

const UserRepo = new Users();

function useUsers() {
    const [users, setUsers] = useState<User[]>([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        UserRepo.getAll()
            .then(allMails => {
                setUsers(allMails)
                setIsLoading(false);
            })
            .catch(error => console.error(error));
    }, []);

    return {users, isLoading, repo: UserRepo}
}

export default useUsers;
