import React, {useEffect, useState} from "react";
// import clsx from "clsx";
import {display, setArgsForComponent, setComponent, setTitle,} from "../store/displayModal/displayModal";

// CARDS
import SmallCard from "../components/SmallCard/SmallCard";
import MediumCard from "../components/MediumCard/MediumCard";

// ICONS
import {faUser} from "@fortawesome/free-solid-svg-icons";

// BUTTONS
import ButtonAdd from "../components/Buttons/ButtonAdd";
// Csv modal
// COMFIRMATION BOX
import {confirmAlert} from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import {deleteAPI, getAPI} from "../services/API/requestsManager";
import openForm from "../components/Formulaire/openForm";
import TopGridDisplay from "../components/TopGridDisplay/TopGridDisplay";
import {Ambassadors as RepoAmbassadors} from "../services/API/Repositories/Ambassadors";
import {useAppDispatch} from "../store/hooks";

const handelDelete = (userToDelete) => {
    const options = {
        closeOnEscape: true,
        closeOnClickOutside: true,
        keyCodeForClose: [8, 32],
        customUI: ({onClose}) => {
            return (
                <div className="bg-gray-200 px-6 py-4 rounded-xl">
                    <h1 className="text-xl text-center mb-4">
                        Supprimer{" "}
                        <span className="text-red-600 font-bold">
              {userToDelete.lastname} {userToDelete.firstname}
            </span>
                    </h1>
                    <p className="text-base pb-3">
                        Êtes-vous sûr de vouloir supprimer cette utilisateur ?
                    </p>

                    <div className="flex gap-4">
                        <button
                            className="shadow rounded px-3 py-1 bg-gray-300"
                            onClick={onClose}
                        >
                            Annuler
                        </button>
                        <button
                            className="shadow rounded px-3 py-1 bg-red-600 text-white"
                            onClick={() => {
                                handelDeleteConfirmed(userToDelete._id);
                                onClose();
                            }}
                        >
                            Supprimer
                        </button>
                    </div>
                </div>
            );
        },
    };
    confirmAlert(options);
};

const handelDeleteConfirmed = (idToDelete) => {
    deleteAPI({objectType: "user", idToDelete});
};

function Ambassadors() {
    const dispatch = useAppDispatch();
    const [ambassadorList, setAmbassadorList] = useState([]);

    const parameterForm = {
        firstname: {label: "Prénom", placeholder: "Prénom"},
        lastname: {label: "Nom", placeholder: "Nom"},
        email: {label: "E-Mail", placeholder: "E-Mail"},
        tel: {label: "Téléphone", placeholder: "Téléphone"},
        school: {
            label: "Ecole",
            placeholder: "Ecole",
            type: "select-one",
            options: [],
        },
        computerPorts: {
            label: "Ports",
            placeholder: "Ports",
            type: "select-multiple",
            options: [
                {label: "HDMI", value: "hdmi"},
                {label: "VGA", value: "vga"},
                {label: "Type C", value: "typeC"}
            ],
        },
        tShirtSize: {
            label: "Taille T-Shirt",
            placeholder: "Taille T-Shirt",
            type: "select-one",
            options: [
                {value: "XS", label: "XS"},
                {value: "S", label: "S"},
                {value: "M", label: "M"},
                {value: "L", label: "L"},
                {value: "XL", label: "XL"},
                {value: "XXL", label: "XXL"}
            ],
        },
        gender: {
            label: "Genre",
            placeholder: "Genre",
            type: "select-one",
            options: [
                {value: 1, label: "Femme"},
                {value: 2, label: "Homme"},
                {value: 3, label: "Intersexe"}
            ]
        },
        schoolYear: {
            label: "Année",
            placeholder: "Année",
            type: "select-one",
            options: [
                {value: 1, label: "N1"},
                {value: 2, label: "N2"},
                {value: 3, label: "N3"},
                {value: 4, label: "N4"},
                {value: 5, label: "N5"}
            ]
        },
        isEnable: {
            label: "Compte activé",
            placeholder: "Compte activé",
            type: "checkbox",
        }
    };

    const paramsOpenModal = {
        parameters: parameterForm,
        objectType: "users",
    };

    getAPI({objectType: "schools"}).then(
        (res) =>
            (paramsOpenModal.parameters.school.options = res.map((school) => {
                return {label: school.name, value: school._id};
            }))
    );

    useEffect(() => {
        (new RepoAmbassadors()).getWithParams("with-stats").then(ambs => {
            if (Array.isArray(ambs))
                setAmbassadorList(ambs);
            else
                setAmbassadorList([]);
        })
    }, []);

    const generateUsersCards = (users) => {
        const lstUserCard = [];
        users.forEach((user) => {
            const {
                _id,
                firstname,
                lastname,
                email,
                tel,
                school,
                schoolYear,
                tShirtSize,
                gender,
                computerPorts,
                isEnable
            } = user;
            const optiUser = {
                _id,
                firstname,
                lastname,
                email,
                tel,
                school,
                schoolYear,
                tShirtSize,
                gender,
                computerPorts,
                isEnable
            };
            lstUserCard.push(
                <MediumCard
                    icon={faUser}
                    key={user._id}
                    titre={user.lastname + " " + user.firstname}
                    textes={[
                        user.school.name ?? "",
                        (user.stats.nb_eventdone ?? 0) + " évènement(s) fait",
                        (user.stats.nb_formsended ?? 0) + " formulaires remplis",
                    ]}
                    menu={[
                        {
                            name: "Supprimer",
                            link: "#",
                            type: "delete",
                            onClick: () => {
                                handelDelete(user);
                            },
                        },
                        {
                            name: "Modifier",
                            link: "#",
                            type: "normal",
                            onClick: () =>
                                openForm({
                                    ...paramsOpenModal,
                                    objectToModify: optiUser,
                                    title: user.lastname + " " + user.firstname,
                                }),
                        },
                    ]}
                />
            );
        });
        return lstUserCard;
    };

    const showModal = () => {
        dispatch(setComponent("CsvModal"));
        dispatch(setArgsForComponent({}));
        dispatch(setTitle("Choisir un fichier à importer"));
        dispatch(display());
    };

    return (
        <>
            {/*<CsvModal/>*/}
            <TopGridDisplay>
                <SmallCard
                    texte="Ambassadors"
                    keyFigure={ambassadorList.length}
                    icon={faUser}
                />
            </TopGridDisplay>
            <div className="flex flex-row justify-end -mb-12 -translate-y-12">
                <ButtonAdd onclick={() => {
                    showModal()
                }}
                />
            </div>
            <TopGridDisplay>{generateUsersCards(ambassadorList)}</TopGridDisplay>
        </>
    );
}

export default Ambassadors;
