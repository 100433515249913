// Importation des pages
import NoPage from "../pages/NoPage";
import Login from "../pages/Login";
import Logout from "../pages/Logout";
import Dashboard from "../pages/Dashboard";
import Forms from "../pages/Forms";
import Events from "../pages/Events";
import Inbox from "../pages/Inbox";
import Roles from "../pages/Roles";
import Users from "../pages/Users";
import AskHelp from "../pages/AskHelp";
import BuildingPage from "../pages/Building";
import Ambassadors from "../pages/Ambassadors";
import Customization from "../pages/Customization";
import PrivacyPolicy from "../pages/PrivacyPolicy";
import Security from "../pages/Parameters/Security";
import AskHelps from "../pages/Developers/AskHelps";
import PrivacyPolicyEn from "../pages/PrivacyPolicyEn";
import CustomizationMail from "../pages/CustomizationMail";
import Navigation from "../components/Navigation/Navigation";
import MyInformation from "../pages/Parameters/MyInformation";
import AmbassadorAttachments from "../pages/AmbassadorAttachments";
import createurFormulaire from "../pages/Formulaire/CreateurFormulaire";

// Formulaires
import { Link } from "react-router-dom";
import React from "react";

const routeAssoc = [];

export const routes = {
  login: {
    path: "/login",
    title: "Connexion",
    component: Login,
  },
  logout: {
    path: "/logout",
    title: "Déconnexion",
    component: Logout,
  },
  admin_ambassadors: {
    security: "admin_ambassador",
    title: "Admin ambassador",
    path: "/ambassadors",
    component: Navigation,
    accessible: false,
    children: {
      dashboard: {
        path: "/dashboard",
        title: "Tableau de bord",
        component: Dashboard,
      },
      forms_path: {
        path: "/formulaires",
        accessible: false,
        children: {
          forms: {
            path: "/",
            title: "Formulaires",
            component: Forms,
          },
          create_new_form: {
            path: "/nouveau_formulaire",
            title: "Nouveau formulaire",
            component: createurFormulaire,
          },
        },
      },
      events: {
        path: "/evenements",
        title: "Évènements",
        component: Events,
      },
      ambassadors: {
        path: "/ambassadors",
        title: "Ambassadors",
        component: Ambassadors,
      },
      ambassadors_attachments: {
        path: "/ambassadors_attachments",
        title: "Fichiers utiles",
        component: AmbassadorAttachments,
      },
      inbox: {
        path: "/inbox",
        title: "Boîte de réception",
        component: Inbox,
      },
    },
  },
  admin_users: {
    security: "admin_users",
    path: "/administration",
    component: Navigation,
    title: "Administration utilisateur",
    accessible: false,
    children: {
      roles: {
        path: "/roles",
        title: "Rôles",
        component: Roles,
      },
      users: {
        path: "/users",
        title: "Utilisateurs",
        component: Users,
      },
    },
  },
  customization: {
    security: "admin_users",
    path: "/customization",
    component: Navigation,
    title: "Personnalisation",
    accessible: false,
    children: {
      customization_colors: {
        path: "/colors",
        title: "Personnalisation / Couleurs",
        component: Customization, // Inserer le composant Customization
      },
      customization_mail: {
        path: "/mail",
        title: "Personnalisation / Mail",
        component: CustomizationMail, // Inserer le composant CustomizationMail
      },
    },
  },
  parameters: {
    path: "/parameters",
    title: "Paramètres",
    component: Navigation,
    accessible: false,
    children: {
      "my-infos": {
        path: "/my-infos",
        title: "Modifier mes informations",
        component: MyInformation,
      },
      security: {
        path: "/security",
        title: "Sécurité",
        component: Security,
      },
      notification: {
        path: "/notification",
        title: "Notifications",
        component: BuildingPage,
      },
      help: {
        path: "/help",
        title: "Aide",
        component: BuildingPage,
      },
      about: {
        path: "/about",
        title: "A propos",
        component: BuildingPage,
      },
    },
  },
  admin_devs: {
    // security: "admin_devs",
    security: "admin_ambassador",
    path: "/developers",
    title: "Administration développeurs",
    accessible: false,
    children: {
      "ask-help": {
        path: "/ask-help",
        title: "Demandes d'aide",
        component: AskHelps,
      },
      report: {
        path: "/ask-help/report/:idAsk",
        title: "Demandes d'aide",
        component: AskHelps,
      },
    },
  },
  privacy: {
    path: "/privacy",
    title: "Privacy Policy",
    accessible: false,
    children: {
      english: {
        path: "/en",
        title: "Privacy Policy",
        component: PrivacyPolicyEn,
      },
      french: {
        path: "/fr",
        title: "Politique de confidentialité",
        component: PrivacyPolicy,
      },
    },
  },
  askhelp: {
    path: "/help",
    title: "Aide",
    component: AskHelp,
  },
  error404: {
    path: "*",
    title: "Page non existante",
    component: NoPage,
  },
};

function getArrayOfRoute(mainRoutes, basePath = "") {
  const test = [];
  for (const [nameRoute, infosRoute] of Object.entries(mainRoutes)) {
    if (infosRoute.children !== undefined)
      test.push(
        ...getArrayOfRoute(infosRoute.children, basePath + infosRoute.path)
      );
    if (infosRoute.children !== undefined)
      test.push(
        ...getArrayOfRoute(infosRoute.children, basePath + infosRoute.path)
      );
    test.push([nameRoute, { ...infosRoute, path: basePath + infosRoute.path }]);
  }
  return test;
}

routeAssoc.push(...getArrayOfRoute(routes));

export function getRoutePathByName(name) {
  return routeAssoc.filter((route) => route[0] === name)[0][1].path;
}

export function getNameByRoutePath(path) {
  const rte = routeAssoc.filter((route) => route[1].path === path)[0];
  if (rte !== undefined) return rte[0];
  else return "dashboard";
  if (rte !== undefined) return rte[0];
  else return "dashboard";
}

export function getTitleByRouteName(name) {
  return routeAssoc.filter((route) => route[0] === name)[0][1].title;
}

export function getAccessibilityByRouteName(name) {
  return (
    routeAssoc.filter((route) => route[0] === name)?.[0]?.[1]?.accessible ??
    null
  );
}

export function getBreadcrumbByRouteName(name) {
  const pathParts = getRoutePathByName(name)
    ?.split("/")
    .filter((part) => part?.trim() !== "");
  const breadcrumbs =
    pathParts?.map((part, partIndex) => {
      const previousParts = pathParts.slice(0, partIndex);
      return {
        label: part,
        href:
          previousParts?.length > 0
            ? `/${previousParts?.join("/")}/${part}`
            : `/${part}`,
        accessible: getAccessibilityByRouteName(part) ?? false,
      };
    }) || [];

  return breadcrumbs.map((breadcrumb, index) => (
    <span key={"bread-crumb-" + index}>
      <span className="self-center text-l font-semibold whitespace-nowrap text-gray-700">
        <Link to={breadcrumb?.href}>{breadcrumb?.label}</Link>
      </span>
      {breadcrumbs.length !== index + 1 && (
        <span className="self-center text-l font-semibold whitespace-nowrap text-gray-700">
          {" / "}
        </span>
      )}
    </span>
  ));
}
