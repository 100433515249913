import React from 'react';

import '../assets/style/privacy-policy.css';

function PrivacyPolicyEn() {
    const options: Intl.DateTimeFormatOptions = {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'};
    const updateDate = new Date("13 Sep 2023");


    return (
        <main id={"privacy-policy"}>
            <header>
                <h1>Ambassador Association Privacy Policy</h1>
                <p>Last Updated: {updateDate.toLocaleDateString(undefined, options)}</p>
            </header>

            <section>
                <h2>Introduction</h2>
                <p>The Ambassador Association (hereinafter referred to as "we," "our," or "the Association") is
                    committed to
                    protecting the privacy of your personal information. This privacy policy explains how we collect,
                    use,
                    protect, and manage your personal data when you use our mobile application, Ambassador.</p>
                <p>Please take the time to carefully read this policy to understand how we handle your personal
                    data.</p>
            </section>

            <section>
                <h2>Collected Data</h2>
                <p>The Ambassador Association collects the following personal information, which is necessary for the
                    proper
                    functioning of the Ambassador mobile application:</p>
                <ul>
                    <li>First and last name</li>
                    <li>Email address</li>
                    <li>Date of birth</li>
                    <li>T-shirt size</li>
                    <li>School within the NEXT-U group</li>
                </ul>
                <p>This data is required to provide you with a complete and personalized experience in our
                    application.</p>
            </section>

            <section>
                <h2>Use of Data</h2>
                <p>The collected data is used exclusively for the proper functioning of the Ambassador mobile
                    application. We
                    use them for the following purposes:</p>
                <ul>
                    <li>User account management.</li>
                    <li>Personalization of the user experience.</li>
                    <li>Communication of specific information related to the Ambassador Association and its
                        activities.
                    </li>
                    <li>Continuous improvement of the application and its features.</li>
                </ul>
            </section>

            <section>
                <h2>Data Sharing</h2>
                <p>The Ambassador Association commits not to transmit your personal data to other companies or
                    organizations.
                    Your personal information will remain strictly confidential and will only be used for the proper
                    operation
                    of the Ambassador application.</p>
            </section>

            <section>
                <h2>Data Hosting</h2>
                <p>The collected data is hosted on IONOS servers located in Germany. We have chosen this option to
                    ensure the
                    security and protection of your data in accordance with the laws of the European Union.</p>
            </section>

            <section>
                <h2>Contact</h2>
                <p>You can contact us at the following email address for any questions or concerns regarding your
                    privacy or
                    this privacy policy:</p>
                <p>Contact Email Address: <a href="mailto:ambassadors@next-u.fr">ambassadors@next-u.fr</a></p>
            </section>

            <section>
                <h2>Physical Address</h2>
                <p>Our offices are located at the following address:</p>
                <p>6 Place Camille Georges<br/>69002 Lyon<br/>France</p>
            </section>

            <section>
                <h2>Changes to this Policy</h2>
                <p>The Ambassador Association reserves the right to update this privacy policy from time to time to
                    reflect
                    changes in our data collection and management practices. The most recent version of this policy will
                    always
                    be available on our website, along with the date of the last update.</p>
            </section>
        </main>
    );
}

export default PrivacyPolicyEn;
