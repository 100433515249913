import {confirmAlert} from "react-confirm-alert";
import {deleteAPI} from "../services/API/requestsManager";


export const handelDelete = (objectType, title, text, objectToDelete, listOfObject, setListOfObject, callback = () => {
}) => {
    const options = {
        closeOnEscape: true,
        closeOnClickOutside: true,
        keyCodeForClose: [8, 32],
        customUI: ({onClose}) => {
            return (
                <div className="bg-gray-200 px-6 py-4 rounded-xl">
                    <h1 className="text-xl text-center mb-4">
                        Supprimer{" "}
                        <span className="text-red-600 font-bold">
                            {title}
                        </span>
                    </h1>
                    <p className="text-base pb-3">
                        {text}
                    </p>
                    <div className="flex gap-4">
                        <button
                            className="shadow rounded px-3 py-1 bg-gray-300"
                            onClick={onClose}
                        >
                            Annuler
                        </button>
                        <button
                            className="shadow rounded px-3 py-1 bg-red-600 text-white"
                            onClick={async () => {
                                const tmp = [];
                                if (!Array.isArray(objectToDelete)) {
                                    const hasBeenRemoved = await handelDeleteConfirmed(objectToDelete._id, objectType, listOfObject, setListOfObject, callback);
                                    if (hasBeenRemoved) tmp.push(objectToDelete._id);
                                } else {
                                    for (const objectToDeleteKey in objectToDelete) {
                                        const toDelete = objectToDelete[objectToDeleteKey];
                                        if (typeof toDelete === "string") {
                                            const hasBeenRemoved = await handelDeleteConfirmed(toDelete, objectType, listOfObject, setListOfObject, callback);
                                            if (hasBeenRemoved) tmp.push(toDelete);
                                        } else {
                                            const hasBeenRemoved = await handelDeleteConfirmed(toDelete._id, objectType, listOfObject, setListOfObject, callback);
                                            if (hasBeenRemoved) tmp.push(toDelete._id);
                                        }
                                    }
                                }
                                if (setListOfObject && listOfObject)
                                    setListOfObject(listOfObject.filter(obj => !tmp.includes(obj._id)));
                                onClose();
                                callback();
                            }}
                        >
                            Supprimer
                        </button>
                    </div>
                </div>
            );
        },
    };
    confirmAlert(options);
};

const handelDeleteConfirmed = async (
    idToDelete,
    objectType,
    listOfObject,
    setListOfObject,
    callback
) => {
    await deleteAPI({objectType, idToDelete}).then((res) => {
        return !!res;
    });
    callback();
};
