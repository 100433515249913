import React, {useEffect, useState} from "react";
import {
    Box,
    Button,
    Chip,
    DialogActions,
    FormControl,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Select,
    TextField,
    useTheme,
} from "@mui/material";
import dayjs from "dayjs";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DateTimePicker} from "@mui/x-date-pickers/DateTimePicker";
import {Users} from "../../../services/API/Repositories/Users";
import {Ambassadors} from "../../../services/API/Repositories/Ambassadors";
import {Schools} from "../../../services/API/Repositories/Schools";
import CustomMenuItem from "../../CutomMenuItem/CustomMenuItem";
import {green, orange, red} from "@mui/material/colors";
import AmbassadorsLimitSelector from "./AmbassadorsLimitSelector/AmbassadorsLimitSelector";
import schedulerToApi from "../transformers/event/schedulerToApi";
import apiToScheduler from "../transformers/event/apiToScheduler";
import {Evenement as Evenement} from "../../../services/API/ObjectTypes/Evenement";
import {Events} from "../../../services/API/Repositories/Events";
// import {frFR} from "@mui/x-date-pickers";
import "dayjs/locale/fr";
import {testEventName} from "../../../hooks/testEventName";
import {DynamicClass} from "../../../services/API/ObjectTypes/DynamicClass";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

function getStyles(name, personName, theme) {
    return {
        fontWeight:
            personName.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}

const repoUsers = new Users();
const repoSchools = new Schools();
const repoEvents = new Events();
const repoAmbassadors = new Ambassadors();

export const CustomEditor = ({scheduler}) => {
    const event = scheduler.edited;
    const theme = useTheme();
    const [schools, setSchools] = useState([
        {_id: 1, name: "WebTech Institut"},
        {_id: 2, name: "ESCEN"},
    ]);
    const [ambassadors, setAmbassadors] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const isNew = event === undefined;

    const subtractHours = (time, hours = 2) => {
        let localTime = null;
        if (time) {
            localTime = new Date(time);
            localTime.setHours(localTime.getHours() - hours);
        }
        return localTime ?? time;
    };

    // Make your own form/state
    const [state, setState] = useState({
        title: event?.title || "",
        description: event?.description || "",
        address: event?.address || "",
        rdv_date:
            event?.rdv_date || subtractHours(scheduler?.state?.start?.value, 1) || "",
        rdv_address: event?.rdv_address || "",
        represented_school: [...(event?.represented_school || [])],
        delegate_per_school: JSON.parse(
            JSON.stringify(event?.delegate_per_school || {})
        ),
        start: event?.start || scheduler?.state?.start?.value || new Date(),
        end:
            event?.end ||
            scheduler?.state?.end?.value ||
            new Date().setHours(new Date().getHours() + 4),
    });
    const [error, setError] = useState("");

    const handleChange = (value, name) => {
        setState((prev) => {
            return {
                ...prev,
                [name]: value,
            };
        });
    };

    const handleRepresentedSchool = (value) => {
        setState((prev) => {
            [...Object.keys(prev.delegate_per_school)].forEach((school) => {
                if (!value.includes(school)) delete prev.delegate_per_school[school];
            });
            return {
                ...prev,
                represented_school: value,
            };
        });
    };

    let initialState = {};

    const handleSubmit = async () => {
        // Your own validation
        if (state.title.length < 3) {
            return setError("Min 3 letters");
        }

        try {
            scheduler.loading(true);

            /**Simulate remote data saving */
            const added_updated_event = await new Promise((res) => {
                /**
                 * Make sure the event have 4 mandatory fields
                 * event_id: string|number
                 * title: string
                 * start: Date|string
                 * end: Date|string
                 */
                const newEvent = {
                    title: state.title,
                    start: scheduler.state.start.value,
                    end: scheduler.state.end.value,
                    description: state.description,
                    ...state,
                };

                newEvent.start =
                    newEvent.start instanceof Date
                        ? newEvent.start.toISOString()
                        : newEvent.start;
                newEvent.end =
                    newEvent.end instanceof Date
                        ? newEvent.end.toISOString()
                        : newEvent.end;
                newEvent.rdv_date =
                    newEvent.rdv_date instanceof Date
                        ? newEvent.rdv_date.toISOString()
                        : newEvent.rdv_date;

                if (isNew) {
                    const eventTransformed = schedulerToApi([newEvent]);
                    repoEvents.save(eventTransformed[0]).then((result) => {
                        if (result !== null) {
                            const nEvent = apiToScheduler([result])[0];
                            res({...nEvent, color: testEventName(nEvent?.title)});
                        } else {
                            alert(
                                "Une erreur est survenue lors de l'enregistrement de l'évènement. Veuillez réessayer dans quelques minutes."
                            );
                        }
                    });
                } else {
                    repoEvents
                        .update(event.event_id, schedulerToApi([newEvent])[0])
                        .then((result) => {
                            const nEvent = apiToScheduler([new Evenement(result)])[0];
                            res({...nEvent, color: testEventName(nEvent?.title)});
                        })
                        .catch((error) => {
                            console.log(error);
                        });
                }
            });

            scheduler.onConfirm(added_updated_event, event ? "edit" : "create");
            scheduler.close();
        } finally {
            scheduler.loading(false);
        }
    };

    useEffect(() => {
        const isLoaded = [false, false];
        const testIfAllLoaded = () => {
            if (isLoaded.filter((isLoad) => isLoad === false).length > 0) return;
            else setIsLoading(false);
        };
        repoSchools.getAll().then((schoolsInApi) => {
            isLoaded[0] = true;
            setSchools(schoolsInApi);
            testIfAllLoaded();
        });

        repoAmbassadors.getWithParams("with-stats").then((ambassadorsInApi: DynamicClass): void => {
                isLoaded[1] = true;
                setAmbassadors(ambassadorsInApi);
                testIfAllLoaded();
            }
        );

        // repoUsers.getWithParams("role", ["Ambassador"]).then((ambassadorsInApi) => {
        //     isLoaded[1] = true;
        //     setAmbassadors(ambassadorsInApi);
        //     testIfAllLoaded();
        // });

        initialState = {
            title: event?.title || "",
            description: event?.description || "",
            rdv_date: event?.rdv_date || "",
            address: event?.address || "",
            rdv_address: event?.rdv_address || "",
            represented_school: event?.represented_school || [],
            delegate_per_school: event?.delegate_per_school || [],
            start: event?.start || new Date(),
            end: event?.end || new Date().setHours(new Date().getHours() + 4),
        };
    }, []);

    useEffect(() => {
        // console.log(state);
    }, [state]);

    const listSchools = Array.isArray(state.represented_school)
        ? state.represented_school
        : [state.represented_school];

    const getAmbassadorById = (ambassadorId) => {
        return (
            ambassadors.filter(
                (ambassador) => ambassador._id === ambassadorId
            )?.[0] ?? null
        );
    };

    const updateSelectStatusFromAmbassador = (
        schoolId,
        ambId,
        isRemoved = false
    ) => {
        const newAmbassadors = [];
        let canBeUpdated = true;
        const addNewAmbassador = () => {
            if (canBeUpdated)
                newAmbassadors.push({
                    ambassador_id: ambId,
                    status: isRemoved ? 2 : 1,
                    status_created: new Date().toISOString(),
                    status_updated: new Date().toISOString(),
                });
        };
        let hasToBeUpdate = true;

        const limitMax = parseInt(state.delegate_per_school[schoolId]?.max ?? 0);
        const actualCount =
            (state.delegate_per_school[schoolId]?.ambassadors?.filter(
                (amb) => amb.status === 1
            )?.length ?? 1) + 1;

        if (actualCount > limitMax) {
            canBeUpdated = false;
        }

        if (
            state.delegate_per_school[schoolId]?.ambassadors &&
            state.delegate_per_school[schoolId]?.ambassadors?.length > 0
        ) {
            const existingAmbassador = state.delegate_per_school[
                schoolId
                ].ambassadors.filter((amb) => amb.ambassador_id === ambId)?.[0];
            if (existingAmbassador)
                if (
                    existingAmbassador.status === 1 &&
                    existingAmbassador.status ===
                    (existingAmbassador.status !== 3
                        ? isRemoved
                            ? 2
                            : 1
                        : existingAmbassador.status)
                ) {
                    hasToBeUpdate = false;
                } else {
                    newAmbassadors.push({
                        ...existingAmbassador,
                        status:
                            existingAmbassador.status !== 3
                                ? isRemoved
                                    ? 2
                                    : canBeUpdated
                                        ? 1
                                        : existingAmbassador.status
                                : existingAmbassador.status,
                        status_updated: new Date().toISOString(),
                    });
                }
            else {
                addNewAmbassador();
            }
            newAmbassadors.push(
                ...state.delegate_per_school[schoolId].ambassadors.filter(
                    (amb) => amb.ambassador_id !== ambId
                )
            );
        } else {
            addNewAmbassador();
        }

        if (hasToBeUpdate)
            setState((prev) => {
                return {
                    ...prev,
                    delegate_per_school: {
                        ...prev.delegate_per_school,
                        [schoolId]: {
                            ambassadors: newAmbassadors,
                            max: prev.delegate_per_school[schoolId]?.max ?? 0,
                        },
                    },
                };
            });
    };

    const updateMaxPerSchool = (newMax, schoolId) => {
        setState((prev) => {
            const sortedCroissant =
                prev.delegate_per_school[schoolId]?.ambassadors
                    ?.filter((amb) => amb.status === 1)
                    ?.sort((a, b) => a.status_updated > b.status_updated) ?? [];

            const updateDelegate = sortedCroissant.map((amb, index) => {
                if (index < sortedCroissant.length - newMax) {
                    amb.status = 2;
                    amb.status_updated = new Date().toISOString();
                }
                return amb;
            });

            const ignoreIds = updateDelegate.map((amb) => amb.ambassador_id);
            const finalArray = prev.delegate_per_school[schoolId]?.ambassadors?.map(
                (amb) => {
                    if (ignoreIds.includes(amb.ambassador_id)) {
                        return updateDelegate.find(
                            (del) => del.ambassador_id === amb.ambassador_id
                        );
                    }
                    return amb;
                }
            );

            return {
                ...prev,
                delegate_per_school: {
                    ...prev.delegate_per_school,
                    [schoolId]: {
                        ambassadors: finalArray,
                        max: newMax,
                    },
                },
            };
        });
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr">
            <div>
                <div
                    style={{padding: "1rem"}}
                    className={"flex flex-col gap-8 min-w-[600px]"}
                >
                    <h2 className={"mb-3 text-center text-2xl uppercase font-bold"}>
                        Nouvel évènement
                    </h2>
                    <TextField
                        label="Nom de l'évenement"
                        value={state.title}
                        onChange={(e) => handleChange(e.target.value, "title")}
                        error={!!error}
                        helperText={error}
                        fullWidth
                    />
                    <DateTimePicker
                        label="Début"
                        value={dayjs(state.start)}
                        onChange={(newValue) =>
                            handleChange(newValue.toISOString(), "start")
                        }
                    />
                    <DateTimePicker
                        label="Fin"
                        value={dayjs(state.end)}
                        onChange={(newValue) => handleChange(newValue.toISOString(), "end")}
                    />
                    <TextField
                        label="Adresse"
                        value={state.address}
                        onChange={(e) => handleChange(e.target.value, "address")}
                        fullWidth
                    />
                    <TextField
                        label="Description"
                        multiline={true}
                        rows={6}
                        value={state.description}
                        onChange={(e) => handleChange(e.target.value, "description")}
                        fullWidth
                    />
                    <TextField
                        label="Adress de rendez-vous"
                        value={state.rdv_address}
                        onChange={(e) => handleChange(e.target.value, "rdv_address")}
                        fullWidth
                    />
                    <DateTimePicker
                        label="Date de rendez-vous"
                        value={dayjs(state.rdv_date)}
                        onChange={(newValue) =>
                            handleChange(newValue.toISOString(), "rdv_date")
                        }
                    />

                    {/* SELECT REPRESENTED SCHOOLS */}
                    <FormControl sx={{m: 0, minWidth: 120}} fullWidth>
                        <InputLabel id="label_schools">Écoles</InputLabel>
                        <Select
                            labelId="label_schools"
                            multiple={true}
                            value={listSchools}
                            label="Écoles"
                            onChange={(e) => handleRepresentedSchool(e.target.value)}
                            inputProps={{"aria-label": "Écoles"}}
                            fullWidth
                            input={<OutlinedInput id="select-multiple-chip" label="Écoles"/>}
                            renderValue={(selected) => (
                                <Box sx={{display: "flex", flexWrap: "wrap", gap: 0.5}}>
                                    {selected.map((key) => {
                                        if (schools) {
                                            const schoolObj = schools.filter(
                                                (school) => school._id === key
                                            )?.[0];
                                            const bgColorSchool =
                                                schoolObj?.colors?.primary ?? "#d3d3d3";
                                            return (
                                                <Chip
                                                    key={key}
                                                    label={schoolObj?.name}
                                                    style={{
                                                        backgroundColor: bgColorSchool,
                                                        color: theme.palette.getContrastText(bgColorSchool),
                                                    }}
                                                />
                                            );
                                        }
                                        return null;
                                    })}
                                </Box>
                            )}
                            MenuProps={MenuProps}
                        >
                            {!isLoading && Array.isArray(schools)
                                ? schools
                                    .filter((school) => school.name?.toLowerCase() !== "next-u")
                                    .map((school) => (
                                        <MenuItem key={school._id} value={school._id}>
                                            {school.name}
                                        </MenuItem>
                                    ))
                                : null}
                        </Select>
                    </FormControl>

                    <AmbassadorsLimitSelector
                        listSchools={listSchools.map((schoolId) => {
                            if (Array.isArray(schools))
                                return schools.filter((school) => school._id === schoolId)?.[0];
                            return [];
                        })}
                        listAmbassadors={ambassadors}
                        updateMaxPerSchool={updateMaxPerSchool}
                        maxPerSchool={state.delegate_per_school}
                    />

                    {/* DYNAMIC AMBASSADORS SELECTOR */}
                    {/*
                        // STATUS
                        // 0 : ASKED
                        // 1 : VALIDATE
                        // 2 : REFUSED
                        // 3 : CANCELED BY USER
                    */}
                    {!isLoading && Array.isArray(listSchools)
                        ? listSchools?.map((idSchoolRepresented) => {
                            const schoolObj = schools.filter(
                                (school) => school._id === idSchoolRepresented
                            )?.[0];
                            const delegatePerSchool =
                                state.delegate_per_school[idSchoolRepresented];
                            const labelField = "Représentants " + schoolObj?.name ?? "";
                            const idDelegate =
                                "school_representative_" + idSchoolRepresented;
                            return (
                                <FormControl
                                    sx={{m: 0, minWidth: 120}}
                                    fullWidth
                                    key={"form_control_" + idSchoolRepresented}
                                >
                                    <InputLabel id={idDelegate}>{labelField}</InputLabel>
                                    <Select
                                        labelId={idDelegate}
                                        multiple={true}
                                        value={
                                            delegatePerSchool?.ambassadors?.filter(
                                                (amb) => amb.status === 1
                                            ) ?? []
                                        }
                                        label={labelField}
                                        onChange={(e) => {
                                        }}
                                        inputProps={{"aria-label": labelField}}
                                        fullWidth
                                        input={
                                            <OutlinedInput
                                                id={"select-multiple-chip" + idSchoolRepresented}
                                                label={labelField}
                                            />
                                        }
                                        renderValue={(ambassadorsSelected) => {
                                            return (
                                                <Box
                                                    sx={{display: "flex", flexWrap: "wrap", gap: 0.5}}
                                                >
                                                    {ambassadorsSelected.map((ambassador) => {
                                                        const objAmbassador = getAmbassadorById(
                                                            ambassador.ambassador_id
                                                        );
                                                        return (
                                                            <Chip
                                                                key={ambassador.ambassador_id}
                                                                label={`${objAmbassador?.lastname ?? ""} ${
                                                                    objAmbassador?.firstname ?? ""
                                                                } (${objAmbassador?.stats.nb_eventdone ?? 0})`}
                                                            />
                                                        );
                                                    })}
                                                </Box>
                                            )
                                        }}
                                        MenuProps={MenuProps}
                                    >
                                        {!isLoading && Array.isArray(ambassadors) ? (
                                            ambassadors?.filter(
                                                (ambs) => ambs.school._id === idSchoolRepresented
                                            ).length > 0 ? (
                                                ambassadors
                                                    .filter(
                                                        (ambs) => ambs.school._id === idSchoolRepresented
                                                    )
                                                    .map((objAmbassador) => {
                                                        const eventAmbassadors =
                                                            delegatePerSchool?.ambassadors ?? [];
                                                        const ambassador = eventAmbassadors.filter(
                                                            (amb) => amb.ambassador_id === objAmbassador._id
                                                        )?.[0];
                                                        const labelItem = `${
                                                            objAmbassador?.lastname ?? ""
                                                        } ${objAmbassador?.firstname ?? ""} (${
                                                            objAmbassador?.stats.nb_eventdone ?? 0
                                                        })`;
                                                        const valueItem = {
                                                            ambassador_id: objAmbassador._id,
                                                            status: ambassador?.status ?? 0,
                                                        };
                                                        let chipColor = "";
                                                        switch (ambassador?.status ?? 0 === 1) {
                                                            case 0:
                                                                chipColor = orange["400"];
                                                                break;
                                                            case 1:
                                                                chipColor = green["400"];
                                                                break;
                                                            case 2:
                                                                chipColor = red["400"];
                                                                break;
                                                        }

                                                        if (objAmbassador)
                                                            return (
                                                                <CustomMenuItem
                                                                    key={
                                                                        idSchoolRepresented +
                                                                        "_" +
                                                                        objAmbassador._id
                                                                    }
                                                                    label={labelItem}
                                                                    value={valueItem}
                                                                    chipColor={chipColor}
                                                                    onSelect={() =>
                                                                        updateSelectStatusFromAmbassador(
                                                                            idSchoolRepresented,
                                                                            objAmbassador._id
                                                                        )
                                                                    }
                                                                    onContextMenu={() =>
                                                                        updateSelectStatusFromAmbassador(
                                                                            idSchoolRepresented,
                                                                            objAmbassador._id,
                                                                            true
                                                                        )
                                                                    }
                                                                />
                                                            );
                                                        return null;
                                                    })
                                            ) : (
                                                <div key={"empty"} className="px-4 py-1">
                                                    Aucun ambassador représente cette école
                                                </div>
                                            )
                                        ) : null}
                                    </Select>
                                </FormControl>
                            );
                        })
                        : null}
                </div>
                <DialogActions>
                    <Button
                        onClick={() => {
                            scheduler.close();
                            setState({
                                title: event?.title || "",
                                description: event?.description || "",
                                rdv_date: event?.rdv_date || "",
                                rdv_address: event?.rdv_address || "",
                                represented_school: event?.represented_school || [],
                                delegate_per_school: event?.delegate_per_school || [],
                                start: event?.start || new Date(),
                                end:
                                    event?.end || new Date().setHours(new Date().getHours() + 4),
                            });
                        }}
                        variant="outlined"
                        color="error"
                    >
                        Annuler
                    </Button>
                    <Button onClick={handleSubmit} variant="contained" color={"primary"}>
                        Confirmer
                    </Button>
                </DialogActions>
            </div>
        </LocalizationProvider>
    );
};
