import {Base, BaseType} from "./Base";

export interface AnswersType extends BaseType {
    table: string;
}

export class Answers extends Base {
    constructor(params?: AnswersType) {
        super({table: "answers", ...params});
    }
}
