import React, { useState, useEffect } from "react";
import clsx from "clsx";
import SideOver from "../../components/SideOver/SideOver";
import { getTypeFields } from "../../services/TypeFields";
import ToolCard from "../../components/ToolCard/ToolCard";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  faChevronRight,
  faChevronLeft,
} from "@fortawesome/free-solid-svg-icons";

function CreateurFormulaire({ ...args }) {
  const [fields, setFields] = useState([]);

  const [isParamsSideOverOpen, setIsParamsSideOverOpen] = useState(false);

  const [isFieldsSideOverOpen, setIsFieldsSideOverOpen] = useState(false);

  useEffect(() => {
    getTypeFields().then((fields) => {
      setFields(fields);
    });
    // document.querySelector("#topNavBar").classList.add("hidden");
    document.querySelector("#sideNavBar").classList.add("hidden");
    document
      .querySelector("#sideNavBar")
      .parentElement.classList.remove("space-x-10");

    return () => {
      document.querySelector("#sideNavBar").classList.remove("hidden");
      document
        .querySelector("#sideNavBar")
        .parentElement.classList.add("space-x-10");
    };
  }, []);

  const generateSideTools = () => {
    let lstElements = [];
    fields.forEach((element) => {
      lstElements.push(
        <ToolCard
          key={element._id}
          toolName={element.name}
          icon={element.icon !== "" ? element.icon : "tools"}
        />
      );
    });
    return lstElements;
  };

  return (
    <div className="h-screen">
      <SideOver
        open={isParamsSideOverOpen}
        setOpen={setIsParamsSideOverOpen}
        side="right"
      />
      <SideOver
        open={isFieldsSideOverOpen}
        setOpen={setIsFieldsSideOverOpen}
        side="left"
        title="Champs"
      >
        <div className="grid grid-cols-3 gap-4">{generateSideTools()}</div>
      </SideOver>
      <div className="h-20">tada</div>
      <div className="flex flex-row flex-1">
        <div
          className={clsx(
            "w-5",
            isFieldsSideOverOpen ? "opacity-0" : "opacity-1 delay-300"
          )}
        >
          <div
            onClick={() => setIsFieldsSideOverOpen(true)}
            className="bg-gray-200 rounded-r-lg h-16 pr-1 absolute flex items-center top-1/2 transform-gpu -translate-y-1/2"
          >
            <FontAwesomeIcon
              icon={isFieldsSideOverOpen ? faChevronLeft : faChevronRight}
              size="xl"
              className="pb-2 transform-gpu translate-y-0.5"
            ></FontAwesomeIcon>
          </div>
        </div>
        <div className="overflow-auto h-auto">
          <div onClick={() => setIsParamsSideOverOpen(true)}>RIGHT</div>
          <div onClick={() => setIsFieldsSideOverOpen(true)}>LEFT</div>
        </div>
      </div>
    </div>
  );
}

export default CreateurFormulaire;
