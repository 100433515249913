import { Link, Outlet, useLocation } from "react-router-dom";
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBookmark,
  faChartBar,
  faClipboard,
  faEdit,
  faUser,
} from "@fortawesome/free-regular-svg-icons";
import {
  faCalendarDays,
  faFilePdf,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
import clsx from "clsx";
import { ReactComponent as NextXLogo } from "../../assets/images/logo-seul-X.svg";
import { getRoutePathByName } from "../../routes/routes";
import "./Sidebar.css";
import { hasAccessTo } from "../../services/AccessManager/AccessManager";

interface SidebarProps {
  children: React.ReactNode;
}

function Sidebar(props: SidebarProps) {
  const location = useLocation();

  const isSelected = (routeName: string) => {
    return location.pathname === getRoutePathByName(routeName);
  };

  const renderLink = (routeName: string, label: string, icon: any) => {
    return (
      <Link
        className={clsx(
          "flex items-center w-full h-12 px-3 my-1 rounded",
          isSelected(routeName) ? "bg-gray-100" : "hover:bg-gray-200"
        )}
        to={getRoutePathByName(routeName)}
      >
        <FontAwesomeIcon
          icon={icon}
          className={clsx(isSelected(routeName) && "icon-clicked")}
        />
        <span
          className={clsx(
            "ml-2 text-sm font-medium",
            isSelected(routeName) && "icon-clicked"
          )}
        >
          {label}
        </span>
      </Link>
    );
  };
  const renderSubMenu = (submenuItems: {
    [key: string]: { label: string; icon: any };
  }) => {
    return Object.entries(submenuItems).map(([routeName, { label, icon }]) => (
      <Link
        key={routeName}
        className={clsx(
          "flex items-center w-full h-12 px-3 my-1 rounded",
          isSelected(routeName) ? "bg-gray-100" : "hover:bg-gray-200"
        )}
        to={getRoutePathByName(routeName)}
      >
        <FontAwesomeIcon
          icon={icon}
          className={clsx(isSelected(routeName) && "icon-clicked")}
        />
        <span
          className={clsx(
            "ml-2 text-sm font-medium",
            isSelected(routeName) && "icon-clicked"
          )}
        >
          {label}
        </span>
      </Link>
    ));
  };

  return (
    <>
      <div className="flex w-screen h-screen space-x-10 bg-gray-300 -mr-10">
        <div
          id="sideNavBar"
          className="flex flex-col items-center w-48 h-full overflow-hidden text-gray-700 bg-gray-300 border-gray-400 border-r-[1px] pr-1"
        >
          <div className="flex items-center justify-between w-full px-3 mt-3">
            <span className="text-sm font-bold">NEXT-U Form</span>
            <NextXLogo height={50} width={50} />
          </div>
          <div className="w-full px-2">
            <div className="inline-flex h-fit items-center justify-center w-full">
              <span className="pr-3 font-medium text-gray-900 +translate-x-1/2">
                Ambassador
              </span>
              <hr className="w-64 h-px my-8 bg-gray-700 border-0 dark:bg-gray-700" />
            </div>
            <div className="flex flex-col items-center w-full">
              {renderLink("dashboard", "Tableau de bord", faChartBar)}
              {renderLink("forms", "Formulaire", faClipboard)}
              {renderLink("events", "Évènements", faCalendarDays)}
              {renderLink("ambassadors", "Ambassadors", faUsers)}
              {renderLink(
                "ambassadors_attachments",
                "Fichiers utiles",
                faFilePdf
              )}
              {/*{renderLink("inbox", "Boîte de réception", faInbox)}*/}
            </div>
            {/* SECTION ADMINISTRATION */}
            {hasAccessTo("admin_users") && (
              <>
                <div className="inline-flex items-center justify-center w-full mt-3">
                  <span className="pr-3 font-medium text-gray-900 +translate-x-1/2">
                    Administration
                  </span>
                  <hr className="w-64 h-px my-8 bg-gray-700 border-0 dark:bg-gray-700" />
                </div>
                <div className="flex flex-col items-center w-full">
                  {renderLink("roles", "Roles", faBookmark)}
                  {renderLink("users", "Utilisateurs", faUser)}
                  <div className="inline-flex items-center justify-center w-full mt-3">
                    <span className="pr-3 font-medium text-gray-900 +translate-x-1/2">
                      Personnalisation
                    </span>
                    <hr className="w-64 h-px my-8 bg-gray-700 border-0 dark:bg-gray-700" />
                  </div>
                  {renderSubMenu({
                    customization_colors: { label: "Couleurs", icon: faEdit },
                    // customization_mail: { label: "Mail", icon: faEdit },
                  })}
                </div>
              </>
            )}
          </div>
          <div className="flex items-center justify-center ml-1 w-full h-16 mt-auto bg-gray-200 hover:bg-gray-300 border-solid border-8 border-sky-600 rounded-xl">
            <span className="ml-2 text-sm font-medium">Documentation</span>
          </div>
        </div>
        <main className="flex flex-1 w-full flex-col pr-10 pb-10 overflow-y-scroll">
          {props.children}
          <Outlet />
        </main>
      </div>
    </>
  );
}

export default Sidebar;
