import React, {useEffect, useState} from 'react';
import {getAPI} from "../../services/API/requestsManager";
import ContainerAskForHelp from "../../components/AskForHelp/ContainerAskForHelp";
import {AskForHelp} from "../../services/API/ObjectTypes/AskForHelp";
import {handelDelete} from "../../hooks/handleDelete";

function SingleAskHelp({idAsk}: { idAsk: string }) {
    const [ask, setAsk] = useState<null | AskForHelp>(null);

    const refreshAskHelp = () => {
        getAPI({objectType: "askForHelp", options: "/" + idAsk}).then(resp => {
            setAsk(resp[0]);
        })
    }

    useEffect(() => {
        refreshAskHelp();
    }, []);

    if (!ask) {
        return (<div>Loading ... </div>)
    }

    const handleDelete = () => {
        handelDelete(
            "askForHelp",
            `Supprimer la demande ?`,
            "La demande ne pourra pas être récupérée une fois supprimée. Toute action est définitive. Êtes-vous sûr de vouloir supprimer cette demande d'aide ? ",
            ask
        );
        // ask.delete()
    }

    return (
        <ContainerAskForHelp>
            <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                <caption
                    className="p-5 text-lg font-semibold text-left text-gray-900 bg-white dark:text-white dark:bg-gray-800">
                    Demandes d'aide : {ask._id}
                </caption>
                <thead
                    className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                    <th scope="col" colSpan={4} className="px-6 py-3 h-10">
                        Informations
                    </th>
                </tr>
                </thead>
                <tbody>
                <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                    <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white w-1/6">
                        Utilisateur
                    </td>
                    <td className="px-6 py-4 w-1/3">
                        {(typeof ask.user === "string" ? ask.user : ask.user?.fullName) ?? ""}
                    </td>
                    <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white w-1/6">
                        Sujet
                    </td>
                    <td className="px-6 py-4 w-1/3">
                        {ask.subject}
                    </td>
                </tr>
                <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                    <th scope="row"
                        className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                        Type de demande
                    </th>
                    <td className="px-6 py-4" colSpan={3}>
                        {ask.messageTypeString}
                    </td>
                </tr>
                <tr className="bg-white border-b dark:bg-gray-700 dark:border-gray-700">
                    <th scope="row" colSpan={4}
                        className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                        Message :
                    </th>
                </tr>
                <tr className="bg-white dark:bg-gray-700">
                    <td className="px-6 pb-4" colSpan={4}>
                        {ask.message}
                    </td>
                </tr>
                <tr className="bg-white dark:bg-gray-800">
                    <td className="px-6 py-4" colSpan={4}>
                        <div className="flex gap-4 justify-end">
                            {typeof ask.user !== "string" &&
                                <a href={"mailto:" + ask.user?.email + "?subject=Réponse demande d'aide " + ask._id + "&body=Bonjour " + ask.user?.fullName +
                                    ",%0A %0A Suite à ta demande d'aide sur l'app Ambassador, voici la réponse que nous pouvons te donner." +
                                    "%0A %0A -------------" +
                                    "%0A %0A Bien cordialement" +
                                    "%0A %0A Les développeurs de l'app Ambassador."}
                                   className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                                    Répondre
                                </a>
                            }
                            <button onClick={handleDelete}
                                    className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded">
                                Archiver
                            </button>
                            {/*<button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">*/}
                            {/*    Button*/}
                            {/*</button>*/}
                        </div>
                    </td>
                </tr>
                </tbody>
            </table>
        </ContainerAskForHelp>
    );
}

export default SingleAskHelp;
