"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.User = void 0;
var Base_1 = require("./Base");
var User = /** @class */ (function (_super) {
    __extends(User, _super);
    function User(params) {
        var _this = _super.call(this, params) || this;
        var _a = params !== null && params !== void 0 ? params : {}, stats = _a.stats, email = _a.email, firstname = _a.firstname, lastname = _a.lastname, tel = _a.tel, roles = _a.roles, school = _a.school, schoolYear = _a.schoolYear, gender = _a.gender, computerPorts = _a.computerPorts, tShirtSize = _a.tShirtSize, isValid = _a.isValid, isEnable = _a.isEnable;
        _this.email = email;
        _this.firstname = firstname;
        _this.lastname = lastname;
        _this.tel = tel;
        _this.roles = roles;
        _this.school = school;
        _this.stats = stats;
        _this.schoolYear = schoolYear;
        _this.gender = gender;
        _this.computerPorts = computerPorts;
        _this.tShirtSize = tShirtSize;
        _this.isValid = isValid;
        _this.isEnable = isEnable;
        _this.table = "users";
        return _this;
    }
    Object.defineProperty(User.prototype, "fullName", {
        get: function () {
            return "".concat(this.lastname, " ").concat(this.firstname);
        },
        enumerable: false,
        configurable: true
    });
    User.prototype.addFormFields = function (_a) {
        var objectJson = _a.objectJson, objectProps = _a.objectProps;
        // First name
        objectProps.firstname.label = "Prénom";
        objectProps.firstname.placeholder = "Prénom";
        objectProps.firstname.order = "10";
        objectProps.firstname.required = true;
        // Last name
        objectProps.lastname.label = "Nom";
        objectProps.lastname.placeholder = "Nom";
        objectProps.lastname.order = "20";
        objectProps.lastname.required = true;
        // E-Mail
        objectProps.email.label = "E-Mail";
        objectProps.email.placeholder = "E-Mail";
        objectProps.email.order = "30";
        objectProps.email.required = true;
        // E-Mail
        objectProps.tel.label = "Téléphone";
        objectProps.tel.placeholder = "Téléphone";
        objectProps.tel.order = "40";
        objectProps.tel.required = true;
        // School
        objectProps.school.type = "select-one";
        objectProps.school.relatedRepo = "schools";
        objectProps.school.label = "École";
        objectProps.school.placeholder = "WebTech, ESCEN, Bachelor...";
        objectProps.school.required = true;
        objectProps.school.order = "50";
        // Role
        objectProps.roles.type = "select-multiple";
        objectProps.roles.relatedRepo = "roles";
        objectProps.roles.label = "Rôles";
        objectProps.roles.placeholder = "Rôles";
        objectProps.roles.required = true;
        objectProps.roles.order = "60";
        // Stats
        objectProps.stats.nb_formsended = { display: false };
        objectProps.stats.nb_forms = { display: false };
        objectProps.stats.nb_eventdone = { display: false };
        objectProps.stats.nb_events = { display: false };
        objectProps.stats.nb_nextevents = { display: false };
        objectProps.stats.level = { display: false };
        // T-Shirt
        objectProps.tShirtSize.label = "Taille T-Shirt";
        objectProps.tShirtSize.placeholder = "T-Shirt";
        objectProps.tShirtSize.order = "70";
        objectProps.tShirtSize.type = "select-one";
        objectProps.tShirtSize.options = [
            { value: "XS", label: "XS" },
            { value: "S", label: "S" },
            { value: "M", label: "M" },
            { value: "L", label: "L" },
            { value: "XL", label: "XL" },
            { value: "XXL", label: "XXL" }
        ];
        // School year
        objectProps.schoolYear.label = "Année scolaire";
        objectProps.schoolYear.placeholder = "Année scolaire";
        objectProps.schoolYear.order = "80";
        objectProps.schoolYear.type = "select-one";
        objectProps.schoolYear.options = [
            { value: 1, label: "N1" },
            { value: 2, label: "N2" },
            { value: 3, label: "N3" },
            { value: 4, label: "N4" },
            { value: 5, label: "N5" }
        ];
        // Gender
        objectProps.gender.label = "Genre";
        objectProps.gender.placeholder = "Genre";
        objectProps.gender.order = "90";
        objectProps.gender.type = "select-one";
        objectProps.gender.options = [
            { value: 1, label: "Femme" },
            { value: 2, label: "Homme" },
            { value: 3, label: "Intersexe" }
        ];
        // Computer ports
        objectProps.computerPorts.label = "Port du PC";
        objectProps.computerPorts.placeholder = "Port du PC";
        objectProps.computerPorts.order = "100";
        objectProps.computerPorts.type = "select-multiple";
        objectProps.computerPorts.options = [
            { label: "HDMI", value: "hdmi" },
            { label: "VGA", value: "vga" },
            { label: "Type C", value: "typeC" }
        ];
        // Account completed
        objectProps.isValid.label = "Compte complété";
        objectProps.isValid.placeholder = "Compte complété";
        objectProps.isValid.order = "110";
        objectProps.isValid.type = "checkbox";
        // Account enabled
        objectProps.isEnable.label = "Compte activé";
        objectProps.isEnable.placeholder = "Compte activé";
        objectProps.isEnable.order = "120";
        objectProps.isEnable.type = "checkbox";
        if (this._id) {
            objectProps._id = {};
            objectProps._id.type = "hidden";
            objectProps._id.value = this._id;
        }
        return { objectJson: objectJson, objectProps: objectProps };
    };
    ;
    return User;
}(Base_1.Base));
exports.User = User;
