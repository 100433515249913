import {Scheduler} from "@aldabil/react-scheduler";
import {fr} from "date-fns/esm/locale";
import {getAPI} from "../../services/API/requestsManager";
import {useEffect, useState} from "react";
import {CustomEditor} from "./CustomEventEditor/CustomEditor";
import {deleteEvent} from "../../services/Events";
import EventPreview from "./EventPreview/EventPreview";
import {testEventName} from "../../hooks/testEventName";

export default function Planning({setIsLoading}) {
    const [fieldsInCalendar, setFieldsInCalendar] = useState([]);

    useEffect(() => {
        setFieldsInCalendar([
            {
                name: "description",
                type: "input",
                config: {label: "Description", multiline: true, rows: 8},
            },
            {
                name: "rdv_address",
                type: "input",
                config: {label: "Adress de rendez-vous", multiline: true, rows: 4},
            },
            {
                name: "rdv_date",
                type: "date",
                config: {
                    label: "Date de rendez-vous",
                    type: "datetime",
                    required: false,
                },
            },
            {
                name: "represented_school",
                type: "date",
                config: {
                    label: "Date de rendez-vous",
                    type: "datetime",
                    required: false,
                },
            },
        ]);
    }, []);

    const fetchRemote = async (query) => {
        const {start, end} = query;
        return new Promise((res) => {
            getAPI({
                objectType: "events",
                options: `/between/${start.toISOString()}/${end.toISOString()}`,
            }).then((events) => {
                res(
                    events.map((event) => {
                        return {
                            ...event.formatCalendar,
                            color: testEventName(event.name),
                        };
                    })
                );
                setIsLoading(false);
            });
        });
    };

    const handleConfirm = async (event, action) => {
        /**
         * Make sure to return 4 mandatory fields:
         * event_id: string|number
         * title: string
         * start: Date|string
         * end: Date|string
         * ....extra other fields depend on your custom fields/editor properties
         */
        // Simulate http request: return added/edited event
        return new Promise((res, rej) => {
            if (action === "edit") {
                /** PUT event to remote DB */
            } else if (action === "create") {
                /**POST event to remote DB */
            }

            const isFail = Math.random() > 0.6;
            // Make it slow just for testing
            setTimeout(() => {
                if (isFail) {
                    rej("Ops... Faild");
                } else {
                    res({
                        ...event,
                        event_id: event.event_id || Math.random(),
                    });
                }
            }, 3000);
        });
    };

    const handleDelete = async (deletedId) => {
        // Simulate http request: return the deleted id
        return new Promise((res, rej) => {
            deleteEvent(deletedId).then((result) => {
                res(result._id);
            });
        });
    };

    const Translations = {
        navigation: {
            month: "Mois",
            week: "Semaine",
            day: "Jour",
            today: "Aujourd'hui",
        },
        form: {
            addTitle: "Ajouter un évenement",
            editTitle: "Modifier un évenement",
            confirm: "Confirmer",
            delete: "Supprimer",
            cancel: "Annuler",
        },
        event: {
            title: "Titre",
            start: "Début",
            end: "Fin",
            allDay: "Toute la journée",
        },
        moreEvents: "Plus...",
        loading: "Chargement...",
    };

    return (
        <Scheduler
            height={300}
            fields={fieldsInCalendar}
            week={{
                weekDays: [0, 1, 2, 3, 4, 5, 6],
                weekStartOn: 1,
                startHour: 7,
                endHour: 24,
                step: 30,
            }}
            locale={fr}
            hourFormat={24}
            translations={Translations}
            getRemoteEvents={fetchRemote}
            onConfirm={handleConfirm}
            onDelete={handleDelete}
            customEditor={(scheduler) => <CustomEditor scheduler={scheduler}/>}
            viewerExtraComponent={(fields, event) => (
                <EventPreview fields={fields} event={event}/>
            )}
        />
    );
}
