import React from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ReportGmailerrorredIcon from "@mui/icons-material/ReportGmailerrorred";
import SaveIcon from "@mui/icons-material/Save";
import { useTheme } from "@mui/material";

export default function ColorPickerChanger(props) {
    const theme = useTheme();
    const {
        isLoading,
        saveStatus,
        setSelectedColorForModification,
        saveColors,
        primaryColor,
        secondaryColor,
    } = props;

    return (
        <>
            <div className="absolute right-8 top-40">
                <LoadingButton
                    disabled={saveStatus === 1}
                    loading={isLoading}
                    loadingPosition="start"
                    startIcon={
                        saveStatus === 1 ? (
                            <CheckCircleIcon />
                        ) : saveStatus === 2 ? (
                            <ReportGmailerrorredIcon />
                        ) : (
                            <SaveIcon />
                        )
                    }
                    onClick={saveColors}
                    style={{
                        color: theme.palette.getContrastText("#fff"),
                    }}
                >
                    {saveStatus === 1 ? "Enregistré" : saveStatus === 2 ? "Erreur" : "Enregistrer"}
                </LoadingButton>
            </div>
            <div className="flex justify-center gap-20 mt-5">
                <div className="flex flex-col items-center gap-5">
                    <h4 className="text-center text-1xl">Couleur primaire</h4>
                    <div
                        onClick={() => setSelectedColorForModification(1)}
                        className={
                            "w-24 h-24 rounded-full flex justify-center border-2 border-neutral-500 cursor-pointer hover:drop-shadow-xl"
                        }
                        style={{ backgroundColor: primaryColor }}
                    ></div>
                </div>
                <div className="flex flex-col items-center gap-5">
                    <h4 className="text-center text-1xl">Couleur secondaire</h4>
                    <div
                        onClick={() => setSelectedColorForModification(2)}
                        className={
                            " w-24 h-24 rounded-full flex justify-center border-2 border-neutral-500 cursor-pointer hover:drop-shadow-xl"
                        }
                        style={{ backgroundColor: secondaryColor }}
                    ></div>
                </div>
            </div>
        </>
    );
}
