// IMPORT DU STORE REDUX
import {store} from "../../store";

// IMPORT DE LA FONCTION D'UPDATE DU TOKEN
import {setBothToken} from "../../store/tokens/tokens";

// IMPORT ROUTES
import {getNameByRoutePath, getRoutePathByName} from "../../routes/routes";

// ============= FONCTION GLOBAL ============= //
export async function fetchAPIwithToken(url, token = null, method = "GET", data = {}, secondTry = false) {
    // refreshTokens();
    if (["GET", "DELETE"].includes(method)) {
        const result = await fetch(url, {
            method: method,
            headers: {
                "Content-Type": "application/json",
                Authorization: token
            }
        })
            .then(
                async (response) => {
                    if (response.status === 403) {
                        if (!secondTry) {
                            const newTokens = await getNewToken();
                            if (newTokens) {
                                return await fetchAPIwithToken(url, 'Ambassador ' + newTokens.accessToken, method, data, true);
                            }
                            return null;
                        } else {
                            window.location.replace(getRoutePathByName('logout') + "?afterTo=" + getNameByRoutePath(window.location.pathname))
                        }
                    } else {
                        return response.json()
                    }
                })
            .then((data) => {
                // if (data.newToken !== undefined) {
                //     refreshToken(data.newToken)
                // }

                return data;
            })
            .catch((error) => {
                console.error("Error:", error);
            });
        return result ?? null;
    } else {
        const result = await fetch(url, {
            method: method,
            headers: {
                "Content-Type": "application/json",
                Authorization: token
            },
            body: JSON.stringify(data)
        })
            .then(
                (response) => response.json())
            .then((data) => {
                return data;
            })
            .catch((error) => {
                console.error("Error:", error);
            });
        return result ?? null;
    }
}

// async function getNewTokens() {
//     const BASE_URL = process.env.REACT_APP_API_URL;
//     const token = await store.getState().tokens.refreshToken;
//     const result = await fetch(BASE_URL + "auth/refreshToken", {
//         method: "POST",
//         headers: {
//             "Content-Type": "application/json",
//         },
//         body: JSON.stringify({
//             token
//         })
//     }).then(response => {
//         return response.json()
//     }).then(json => {
//         console.log(json)
//     })
//     console.log(result);
// }

export async function getNewToken() {
    const headers = {
        Accept: "application/json",
        "Content-Type": "application/json"
    };

    const BASE_URL = process.env.REACT_APP_API_URL;
    const REFRESH_URL = `${BASE_URL}auth/refreshToken`;

    const userInfos = store.getState().userInfos;
    const tokens = store.getState().tokens;

    try {

        // console.log(userInfos._id);
        // console.log(tokens.permanentToken);

        const response = await fetch(REFRESH_URL, {
            method: "POST",
            headers,
            body: JSON.stringify({
                token: tokens.permanentToken,
                userId: userInfos._id
            })
        }).then(res => {
            return res.json()
        });

        if (response.accessToken) {
            store.dispatch(setBothToken(response));
        }

        return response;
    } catch (error) {
        console.error("(index.js->getNewToken():82) Error:", error);
        return null;
    }
}

// async function refreshTokens() {
//     const BASE_URL = process.env.REACT_APP_API_URL;
//     const token = await store.getState().tokens.refreshToken;
//     const result = await fetch(BASE_URL + "auth/refreshToken", {
//         method: "POST",
//         headers: {
//             "Content-Type": "application/json",
//         },
//         body: JSON.stringify({
//             token
//         })
//     }).then(response => {
//         return response.json()
//     }).then(json => {
//         console.log(json)
//     })
//     console.log(result);
// }
