import {configureStore} from '@reduxjs/toolkit'
import userInfosReducer from './userInfos/userInfosSlice'
import displayModal from './displayModal/displayModal'
import sideOvers from "./sideOvers/sideOvers";
import tokens from "./tokens/tokens";

import {loadState, saveState} from "./localStorage";

export const store = configureStore({
    reducer: {
        userInfos: userInfosReducer,
        displayModal: displayModal,
        sideOvers: sideOvers,
        tokens: tokens
    },
    preloadedState: loadState()
})

store.subscribe(() => {
    saveState(store.getState());
});


// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
