import React, {useEffect, useState} from "react";
import clsx from "clsx";
import {getAPI, putAPI} from "src/services/API/requestsManager";
import {ChromePicker} from "react-color";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faX} from "@fortawesome/free-solid-svg-icons";
import {useTheme} from "@mui/material";
import ColorComponentChanger from "../components/Customization/ColorComponentChanger";
import ColorPickerChanger from "../components/Customization/ColorPickerChanger";
import ColorScoolSelect from "../components/Customization/ColorScoolSelect";

function Customization() {

    const theme = useTheme();
    const [isLoading, setIsLoading] = useState(true);
    const [isBlinking, setIsBlinking] = useState(true);
    const [saveStatus, setSaveStatus] = useState(0);
    const [selectedSchool, setSelectedSchool] = useState("");
    const [primaryColorSelected, setPrimaryColorSelected] = useState("#0086cc");
    const [secondaryColorSelected, setSecondaryColorSelected] =
        useState("#ffc229");
    const [selectedColorForModification, setSelectedColorForModification] =
        useState(0);

    useEffect(() => {
        getAPI({objectType: "schools", options: "/name/NEXT-U"}).then(
            (schools) => {
                setSelectedSchool(schools._id);
                setPrimaryColorSelected(schools?.colors?.primary);
                setSecondaryColorSelected(schools?.colors?.secondary);
                setIsLoading(false);
                setSaveStatus(1);
            }
        );
    }, []);

    useEffect(() => {
        setSaveStatus(1);
    }, [selectedSchool]);

    useEffect(() => {
        setTimeout(
            () => {
                setIsBlinking(!isBlinking);
            },
            isBlinking ? 1500 : 500
        );
    }, [isBlinking]);

    // -- Color function
    const primaryColor = primaryColorSelected;
    const secondaryColor = secondaryColorSelected;
    const saveColors = () => {
        setIsLoading(true);
        putAPI({
            objectType: "schools",
            idToModify: selectedSchool,
            dataToTransfert: {
                colors: {
                    primary: primaryColorSelected,
                    secondary: secondaryColorSelected,
                },
            },
        }).then((res) => {
            if (!res) {
                alert("Une erreur est survenue lors de l'enregistrement des données");
                setSaveStatus(2);
            } else
                setTimeout(() => {
                    setSaveStatus(1);
                    setIsLoading(false);
                }, 500);
        });
    };

    return (
        <>
            <div
                className={clsx(
                    "absolute bg-black bg-opacity-50 backdrop-blur-sm w-full h-full z-10 left-0 top-0 flex justify-center items-center",
                    selectedColorForModification !== 1 ? "hidden" : ""
                )}
            >
                <div className="h-fit relative">
                    <FontAwesomeIcon
                        icon={faX}
                        onClick={() => setSelectedColorForModification(0)}
                        className="cursor-pointer absolute -right-5 -top-5 z-30 h-4 w-4 bg-gray-400 rounded-full p-0.5"
                    />
                    <ChromePicker
                        color={primaryColorSelected}
                        onChange={(color) => {
                            setSaveStatus(0);
                            setPrimaryColorSelected(color.hex);
                        }}
                        colors={[]}
                    />
                </div>
            </div>
            <div
                className={clsx(
                    "absolute bg-black bg-opacity-50 backdrop-blur-sm w-full h-full z-10 left-0 top-0 flex justify-center items-center",
                    selectedColorForModification !== 2 ? "hidden" : ""
                )}
            >
                <div className="h-fit relative">
                    <FontAwesomeIcon
                        icon={faX}
                        onClick={() => setSelectedColorForModification(0)}
                        className="cursor-pointer absolute -right-5 -top-5 z-30 h-4 w-4 bg-gray-400 rounded-full p-0.5"
                    />
                    <ChromePicker
                        color={secondaryColorSelected}
                        onChange={(color) => {
                            setSaveStatus(0);
                            setSecondaryColorSelected(color.hex);
                        }}
                        colors={[]}
                    />
                </div>
            </div>
            <h4 className="text-2xl text-center mb-5">
                Palette de couleur de l'application mobile
            </h4>
            <div className="bg-white rounded-md px-20 py-10 relative">
                <ColorScoolSelect setPrimaryColorSelected={setPrimaryColorSelected}
                                  setSecondaryColorSelected={setSecondaryColorSelected}
                                  setSchoolId={setSelectedSchool}
                />
                <ColorPickerChanger
                    saveStatus={saveStatus}
                    saveColors={saveColors}
                    setSelectedColorForModification={setSelectedColorForModification}
                    primaryColor={primaryColorSelected}
                    secondaryColor={secondaryColorSelected}
                />
                <ColorComponentChanger primaryColor={primaryColor} secondaryColor={secondaryColor}/>
            </div>
        </>
    );
}

export default Customization;
